<div class="page-title-area page-banner-faq">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Frequently Asked Questions' | translate }} - {{ 'Atopic Dermatitis' | translate }}</h2>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head"></div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>{{ 'Who do I contact to find out more about Atopic Dermatitis?' | translate }}</a>
                            <p>{{ 'Click on the Support Group menu item at the top of the screen.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>{{ 'Is Atopic Dermatitis contagious?' | translate }}</a>
                            <p>{{ 'Watch video 3 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>{{ 'Is hot water or warm water better when showering?' | translate }}</a>
                            <p>{{ 'Watch video 2 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>{{ 'Do adults get Atopic Dermatitis?' | translate }}</a>
                            <p>{{ 'Watch video 1 for more information.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'Do allergens cause Atopic Dermatitis?' | translate }}</a>
                            <p>{{ 'Click on the Resources menu item at the top of the screen.' | translate }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
