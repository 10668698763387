import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/components/language/language.service';

@Component({
    selector: 'app-modules-card',
    templateUrl: './modules-card.component.html',
    styleUrls: ['./modules-card.component.css'],
})
export class ModulesCardComponent implements OnInit {
    @Input() topic = '';
    @Input() topicText = '';
    @Input() icon = 'icofont-doctor';
    @Input() topicLink = '';
    constructor(private languageService: LanguageService) {}

    ngOnInit(): void {
        this.languageService.showRespiratory$.subscribe((showRespiratory) => {
            if (this.topic == 'Respiratory' && !showRespiratory) {
                this.topicLink = '';
            }
        });
        this.languageService.showLiverDisease$.subscribe((showLiverDisease) => {
            if (this.topic == 'Liver Disease' && !showLiverDisease) {
                this.topicLink = '';
            }
        });
        this.languageService.showPain$.subscribe((showPain) => {
            if (this.topic == 'Pain' && !showPain) {
                this.topicLink = '';
            }
        });
        this.languageService.showSharedDecisionMaking$.subscribe((showSharedDecisionMaking) => {
            if (this.topic == 'Shared decision making' && !showSharedDecisionMaking) {
                this.topicLink = '';
            }
        });
    }
}
