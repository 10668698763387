<!-- <div
    class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp"
    data-wow-delay=".3s"
> -->
<div class="service-item card-block">
    <div class="service-front">
        <i [class]="icon"></i>
        <h3>{{ topic | translate }}</h3>
        <p>
            {{ topicText | translate }}
        </p>
    </div>

    <div class="service-end card-block">
        <i [class]="icon"></i>
        <h3>{{ topic | translate }}</h3>
        <p *ngIf="topicLink">{{ 'Click here to view videos' | translate }}</p>
        <a *ngIf="topicLink" [routerLink]="topicLink">{{ 'View' | translate }}</a>
        <h3 *ngIf="!topicLink">{{ 'Coming soon' | translate }}</h3>
    </div>
</div>
<!-- </div> -->
