import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-resources-atopic-dermatitis',
    templateUrl: './resources-atopic-dermatitis.component.html',
    styleUrls: ['./resources-atopic-dermatitis.component.scss'],
})
export class ResourcesAtopicDermatitisComponent {
    constructor(public dialogRef: MatDialogRef<ResourcesAtopicDermatitisComponent>) {}

    close() {
        this.dialogRef.close();
    }
}
