import { Injectable } from '@angular/core';

import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { AtopicDermatitisAfterVideo1 } from '../models/atopic-dermatitis-after-video-1.model';

@Injectable({
    providedIn: 'root',
})
export class AtopicDermatitisAfterVideo1Service {
    private collectionPath = 'atopicDermatitisAfterVideo1';
    atopicDermatitisAfterVideo1s$: Observable<AtopicDermatitisAfterVideo1[]>;

    constructor(private afs: AngularFirestore) {}

    getAtopicDermatitisAfterVideo1s$(pageNumber = 0, pageSize = 100): Observable<AtopicDermatitisAfterVideo1[]> {
        return this.afs
            .collection<AtopicDermatitisAfterVideo1>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('lastName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    saveAtopicDermatitisAfterVideo1(atopicDermatitisAfterVideo1: AtopicDermatitisAfterVideo1) {
        if (atopicDermatitisAfterVideo1.id) {
            return this.updateAtopicDermatitisAfterVideo1(atopicDermatitisAfterVideo1);
        }
        return this.createAtopicDermatitisAfterVideo1(atopicDermatitisAfterVideo1);
    }

    private createAtopicDermatitisAfterVideo1(atopicDermatitisAfterVideo1: AtopicDermatitisAfterVideo1) {
        atopicDermatitisAfterVideo1.createdOn = new Date();
        return this.afs.collection(this.collectionPath).add(atopicDermatitisAfterVideo1);
    }

    private updateAtopicDermatitisAfterVideo1(atopicDermatitisAfterVideo1: AtopicDermatitisAfterVideo1) {
        return this.afs
            .doc(`${this.collectionPath}/` + atopicDermatitisAfterVideo1.id)
            .update(atopicDermatitisAfterVideo1);
    }

    deleteAtopicDermatitisAfterVideo1(id: string) {
        this.afs.doc(`${this.collectionPath}/` + id).delete();
    }
}
