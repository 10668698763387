import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-resources-mental-health',
    templateUrl: './resources-mental-health.component.html',
    styleUrls: ['./resources-mental-health.component.scss'],
})
export class ResourcesMentalHealthComponent {
    constructor(public dialogRef: MatDialogRef<ResourcesMentalHealthComponent>) {}

    close() {
        this.dialogRef.close();
    }
}
