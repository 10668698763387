import { Component } from "@angular/core";

@Component({
    selector: "app-faq-mental-health",
    templateUrl: "./faq-mental-health.component.html",
    styleUrls: ["./faq-mental-health.component.scss"],
})
export class FaqMentalHealthComponent {
    constructor() {}
}
