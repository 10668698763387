<section class="speciality-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-12">
                <div>
                    <div class="section-title-two">
                        <span>{{ 'Resources' | translate }} - {{ 'Respiratory' | translate }} </span>
                        <h2>{{ 'Links' | translate }}</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div *ngFor="let resource of resources" class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ resource.title | translate }}</h3>
                                    <p>{{ resource.organization | translate }}</p>
                                    <div class="button-tray">
                                        <a href="{{ resource.url }}" target="_blank" class="btn btn-primary white-text">
                                            {{ 'Click to Open' | translate }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div>
    <h2>{{ 'Disclaimer' | translate }}</h2>
    <p>
        {{
            'The website and content are for educational purposes only and do not reflect any diagnosis or treatment specific to your condition. For queries regarding your condition, please consult a qualified medical professional.'
                | translate
        }}'
    </p>
</div>

<div class="card-footer">
    <button class="btn btn-danger mr-1" (click)="close()">{{ 'Close' | translate }}</button>
</div>
