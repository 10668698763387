import { Injectable } from '@angular/core';

import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { AtopicDermatitisBefore } from '../models/atopic-dermatitis-before.model';

@Injectable({
    providedIn: 'root',
})
export class AtopicDermatitisBeforeService {
    private collectionPath = 'atopicDermatitisBefore';
    atopicDermatitisBefores$: Observable<AtopicDermatitisBefore[]>;

    constructor(private afs: AngularFirestore) {}

    getAtopicDermatitisBefores$(pageNumber = 0, pageSize = 100): Observable<AtopicDermatitisBefore[]> {
        return this.afs
            .collection<AtopicDermatitisBefore>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('lastName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    saveAtopicDermatitisBefore(atopicDermatitisBefore: AtopicDermatitisBefore) {
        if (atopicDermatitisBefore.id) {
            return this.updateAtopicDermatitisBefore(atopicDermatitisBefore);
        }
        return this.createAtopicDermatitisBefore(atopicDermatitisBefore);
    }

    private createAtopicDermatitisBefore(atopicDermatitisBefore: AtopicDermatitisBefore) {
        atopicDermatitisBefore.createdOn = new Date();
        return this.afs.collection(this.collectionPath).add(atopicDermatitisBefore);
    }

    private updateAtopicDermatitisBefore(atopicDermatitisBefore: AtopicDermatitisBefore) {
        return this.afs.doc(`${this.collectionPath}/` + atopicDermatitisBefore.id).update(atopicDermatitisBefore);
    }

    deleteAtopicDermatitisBefore(id: string) {
        this.afs.doc(`${this.collectionPath}/` + id).delete();
    }
}
