import { Component } from '@angular/core';

@Component({
    selector: 'app-support-group-atopic-dermatitis',
    templateUrl: './support-group-atopic-dermatitis.component.html',
    styleUrls: ['./support-group-atopic-dermatitis.component.scss'],
})
export class SupportGroupAtopicDermatitisComponent {
    // data = [
    //     ['India', 'https://m.facebook.com/eczemasingapore/community'],
    //     ['South Africa', 'https://www.altogethereczema.org/'],
    //     ['United States of America', 'America'],
    //     ['China', 'China'],
    //     ['China', 'https://m.facebook.com/eczemasingapore/community'],
    //     ['South America', 'South America'],
    //     ['England', 'England'],
    //     ['Australia', 'https://www.eczema.org.au/'],
    //     ['South America', 'www'],
    // ];

    // // title = "Browser market shares at a specific website, 2014";

    // width = '1024';

    // columnNames = ['Browser', 'Continent', 'Percentage'];

    // options: {
    //     region: 'world';
    //     displayMode: 'text';
    //     resolution: 'continents';
    //     backgroundColor: '#81d4fa';
    //     colorAxis: { colors: ['#00853f', 'black', '#e31b23'] };
    // };

    // type = 'GeoChart';

    // geoChartData = {
    //     chartType: "GeoChart",
    //     dataTable: [
    //         ["City", "Population"],
    //         ["Melbourne", 456789],
    //     ],
    //     options: {
    //         region: "IT",
    //         displayMode: "markers",
    //     },
    // };

    constructor() {
        const mapPoints = document.querySelectorAll('.map-point');

        mapPoints.forEach((point) => {
            point.addEventListener('mousedown', function (event) {
                event.preventDefault();
                this.focus();
            });
        });
    }

    blurButton(event: any): void {
        event.target.blur();
    }
}
