import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/components/language/language.service';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
    @Input() topic = '';
    @Input() text = '';
    @Input() bullets = ['Videos', 'Resources', 'FAQs'];
    @Input() image = '';
    @Input() buttonUrl = '/about';
    @Input() buttonText = 'View';
    constructor(private languageService: LanguageService, private router: Router) {}

    ngOnInit(): void {
        this.languageService.showRespiratory$.subscribe((showRespiratory) => {
            if (this.topic == 'Respiratory' && !showRespiratory) {
                this.buttonText = 'Coming soon';
                this.buttonUrl = '#';
            }
        });
        this.languageService.showLiverDisease$.subscribe((showLiverDisease) => {
            if (this.topic == 'Liver Disease' && !showLiverDisease) {
                this.buttonText = 'Coming soon';
                this.buttonUrl = '#';
            }
        });
        this.languageService.showPain$.subscribe((showPain) => {
            if (this.topic == 'Pain' && !showPain) {
                this.buttonText = 'Coming soon';
                this.buttonUrl = '#';
            }
        });
        this.languageService.showSharedDecisionMaking$.subscribe((showSharedDecisionMaking) => {
            if (this.topic == 'Shared decision making' && !showSharedDecisionMaking) {
                this.buttonText = 'Coming soon';
                this.buttonUrl = '#';
            }
        });
    }

    handleClick(event: MouseEvent): void {
        if (this.buttonUrl === '#') {
            event.preventDefault(); // Prevent the default action for '#' URLs
        } else {
            // Use the URL to navigate
            event.preventDefault();
            const url = new URL(this.buttonUrl, window.location.origin);
            const queryParams = {};
            url.searchParams.forEach((value, key) => {
                queryParams[key] = value;
            });
            this.router.navigate([url.pathname], { queryParams: queryParams });
        }
    }
}
