import { Component } from "@angular/core";

@Component({
    selector: "app-faq-atopic-dermatitis",
    templateUrl: "./faq-atopic-dermatitis.component.html",
    styleUrls: ["./faq-atopic-dermatitis.component.scss"],
})
export class FaqAtopicDermatitisComponent {
    constructor() {}
}
