import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BeforePopupMHComponent } from './before-question/before-popup.component';
import { Video } from 'src/app/models/video.model';

@Component({
    selector: 'app-mental-health',
    templateUrl: './mental-health.component.html',
    styleUrls: ['./mental-health.component.scss'],
})
export class MentalHealthComponent {
    constructor(private dialog: MatDialog) {}

    videos: Video[] = [
        {
            topic: 'Major Depressive Disorder',
            text: 'Professor Roger McIntyre helps you understand Major Depressive Disorder (MDD)',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: 'mental-health-video-1-length',
            url: 'mental-health-video-1',
            poster: 'mental-health-video-1-poster',
            videoNumber: 1,
        },
        {
            topic: 'Anxiety',
            text: 'Find out more about anxiety, the symptoms of anxiety, stigma and understanding what you are feeling.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: 'mental-health-video-2-length',
            url: 'mental-health-video-2',
            poster: 'mental-health-video-2-poster',
            videoNumber: 2,
        },
        {
            topic: 'Mental Health and Noncommunicable Diseases (NCDs)',
            text: 'Dr. Mohammad Alsuwaidan helps you understand what is Noncommunicable Diseases and how it is linked to Mental Health',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: 'mental-health-video-3-length',
            url: 'mental-health-video-3',
            poster: 'mental-health-video-3-poster',
            videoNumber: 3,
        },
    ];

    // beforePlayVideo(video) {
    //     // if (localStorage.getItem('isSubmitted') !== 'true') {
    //     //     video.pause();
    //     //     this.openBeforeQuestion(video);
    //     // }
    // }

    // openBeforeQuestion(video, data: any = {}, isNew?) {
    //     const title = isNew ? 'Take Survey' : 'Update Survey';
    //     const dialogRef: MatDialogRef<any> = this.dialog.open(BeforePopupMHComponent, {
    //         width: '1024px',
    //         disableClose: true,
    //         data: { title: title, faq: data },
    //     });

    //     dialogRef.afterClosed().subscribe((res) => {
    //         video.play();
    //         if (!res) {
    //             return;
    //         }
    //         // if (isNew) {
    //         //     // this.createSurvey(res);
    //         //     video.play();
    //         // } else {
    //         //     // this.updateSurvey(res);
    //         //     video.play();
    //         // }
    //     });
    // }
}
