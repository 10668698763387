<div class="page-title-area page-banner-faq">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Frequently Asked Questions' | translate }} - {{ 'Liver Disease' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Faq</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Liver Disease</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>{{ 'Why is the liver one of the important organs in the human body?' | translate }}</a>
                            <p>{{ 'Watch video 1 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>{{
                                'Does excessive consumption of medicines such as pain killers, fever reducers lead to liver damage?'
                                    | translate
                            }}</a>
                            <p>{{ 'Watch video 2 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>{{
                                'What is the association between fatty liver and conditions like diabetes, obesity, dyslipidemia, and hypertension?'
                                    | translate
                            }}</a>
                            <p>{{ 'Watch video 3 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>{{ 'How does alcohol impact the overall liver function?' | translate }}</a>
                            <p>{{ 'Watch video 4 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'What are the signs and symptoms of viral hepatitis?' | translate }}</a>
                            <p>{{ 'Watch video 5 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'Who do I contact to find out more about liver disease?' | translate }}</a>
                            <p>{{ 'Click on the Support Group menu item at the top of the screen.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'Where do I find more information about liver disease?' | translate }}</a>
                            <p>{{ 'Click on the Resources menu item at the top of the screen.' | translate }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
