import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Video } from 'src/app/models/video.model';
// import { BeforePopupMHComponent } from './before-question/before-popup.component';

@Component({
    selector: 'app-cardiovascular-disease',
    templateUrl: './cardiovascular-disease.component.html',
    styleUrls: ['./cardiovascular-disease.component.scss'],
})
export class CardioVascularDiseaseComponent {
    constructor(private dialog: MatDialog) {}

    videos: Video[] = [
        {
            topic: 'CVD: Overall risk factors',
            text: 'Understand CVD and its risk factors',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '3.38',
            url: 'cardiovascular-disease-video-1',
            poster: 'cardiovascular-disease-video-1-poster',
            videoNumber: 1,
        },
        {
            topic: 'CVD: Hypertension',
            text: 'Prof S. Bangalore helps you understand hypertension.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '5.41',
            url: 'cardiovascular-disease-video-2',
            poster: 'cardiovascular-disease-video-2-poster',
            videoNumber: 2,
        },
        {
            topic: 'CVD: Dyslipidemia Part 1',
            text: 'Prof P. Lansberg helps you understand cholesterol.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '4.16',
            url: 'cardiovascular-disease-video-3',
            poster: 'cardiovascular-disease-video-3-poster',
            videoNumber: 3,
        },
        {
            topic: 'CVD: Dyslipidemia Part 2',
            text: 'Prof Peter Lansberg helps you understand how to manage dyslipidemia.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '5.02',
            url: 'cardiovascular-disease-video-4',
            poster: 'cardiovascular-disease-video-4-poster',
            videoNumber: 4,
        },
        {
            topic: 'CVD: Thrombosis',
            text: 'Find out more about thrombosis [blood clots].',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '4.31',
            url: 'cardiovascular-disease-video-5',
            poster: 'cardiovascular-disease-video-5-poster',
            videoNumber: 5,
        },
    ];

    //https://patient-academy.s3.ap-east-1.amazonaws.com/2.Hypertension.mp4
    // beforePlayVideo(video) {
    //     // if (localStorage.getItem('isSubmitted') !== 'true') {
    //     //     video.pause();
    //     //     this.openBeforeQuestion(video);
    //     // }
    // }

    // openBeforeQuestion(video, data: any = {}, isNew?) {
    //     const title = isNew ? 'Take Survey' : 'Update Survey';
    //     const dialogRef: MatDialogRef<any> = this.dialog.open(BeforePopupMHComponent, {
    //         width: '1024px',
    //         disableClose: true,
    //         data: { title: title, faq: data },
    //     });

    //     dialogRef.afterClosed().subscribe((res) => {
    //         video.play();
    //         if (!res) {
    //             return;
    //         }
    //         // if (isNew) {
    //         //     // this.createSurvey(res);
    //         //     video.play();
    //         // } else {
    //         //     // this.updateSurvey(res);
    //         //     video.play();
    //         // }
    //     });
    // }
}
