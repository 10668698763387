import { Component } from '@angular/core';
import { Video } from 'src/app/models/video.model';

@Component({
    selector: 'app-urology',
    templateUrl: './urology.component.html',
    styleUrls: ['./urology.component.scss'],
})
export class UrologyComponent {
    constructor() {}

    videos: Video[] = [
        {
            topic: 'ED video Symptoms and Risk Factors',
            text: 'To better understand the Symptoms and Risk Factors of Erectile Dysfunction (ED) watch this video.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '5.20',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/urology%2FViatris_ED%20video%20Symptoms%20and%20Risk%20Factors%20-%20Part%201.%205Sept24.mp4?alt=media&token=e89ba1a8-75a0-41d6-b644-cda450d4bfcf',
            poster: 'assets/img/urology/1.png',
            videoNumber: 1,
        },
        {
            topic: 'ED video Diagnosis and Management',
            text: 'To find out more about the Diagnosis and  Management of Erectile Dysfunction watch this video.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '4.01',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/urology%2FViatris_ED%20video%20Diagnosis%20and%20Management%20-%20Part%202%205Sept24.mp4?alt=media&token=f4df6bd0-5abf-4507-bb34-7184ad39a2af',
            poster: 'assets/img/urology/2.png',
            videoNumber: 2,
        },
        {
            topic: 'Podcast Mens Health-ED',
            text: 'Listen to experts in their field talk about Erectile Dysfunction (ED).',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '33.33',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/urology%2F3.PODCAST%20MENS%20HEALTH-ED.mp4?alt=media&token=2f9a050a-18bf-44b8-acdf-3aaab88652ca',
            poster: 'assets/img/urology/3.png',
            videoNumber: 3,
        },
    ];
}
