import { Component } from "@angular/core";

@Component({
    selector: "app-faq-pain",
    templateUrl: "./faq-pain.component.html",
    styleUrls: ["./faq-pain.component.scss"],
})
export class FaqPainComponent {
    constructor() {}
}
