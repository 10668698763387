import { Component } from "@angular/core";

@Component({
    selector: "app-services-details",
    templateUrl: "./services-details.component.html",
    styleUrls: ["./services-details.component.scss"],
})
export class ServicesDetailsComponent {
    constructor() {}
}
