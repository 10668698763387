import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BeforePopupComponent } from './before-question/before-popup.component';
import { Video } from 'src/app/models/video.model';
import { LanguageService } from 'src/app/components/language/language.service';
@Component({
    selector: 'app-atopic-dermatitis',
    templateUrl: './atopic-dermatitis.component.html',
    styleUrls: ['./atopic-dermatitis.component.scss'],
})
export class AtopicDermatitasComponent {
    showApp: boolean;

    constructor(private dialog: MatDialog, private languageService: LanguageService) {
        this.languageService.showApp$.subscribe((visible) => {
            this.showApp = visible;
        });
    }

    videos: Video[] = [
        {
            topic: 'Introduction to Atopic Dermatitis',
            text: 'Get your answers on the basics of Atopic Dermatitis: who gets it, how common it is.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: 'atopic-dermatitis-video-1-length',
            url: 'atopic-dermatitis-video-1',
            poster: 'atopic-dermatitis-video-1-poster',
            videoNumber: 1,
        },
        {
            topic: 'Atopic Dermatitis: Symptoms and impact on Quality of Life',
            text: 'Learn about the symptoms, flare-ups, triggers, and diagnosis of Atopic Dermatitis.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: 'atopic-dermatitis-video-2-length',
            url: 'atopic-dermatitis-video-2',
            poster: 'atopic-dermatitis-video-2-poster',
            videoNumber: 2,
        },
        {
            topic: 'Management of Atopic Dermatitis',
            text: 'Prof. Dr. Dr.h.c. Thomas Luger provides his expert tips on managing your Atopic Dermatitis.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: 'atopic-dermatitis-video-3-length',
            url: 'atopic-dermatitis-video-3',
            poster: 'atopic-dermatitis-video-3-poster',
            videoNumber: 3,
        },
        {
            topic: 'Understanding Atopic Dermatitis for Kids',
            text: 'An animated video to answer frequently asked questions kids have on Atopic Dermatitis',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: 'atopic-dermatitis-video-3-length',
            url: 'atopic-dermatitis-video-4',
            poster: 'atopic-dermatitis-video-4-poster',
            videoNumber: 4,
        },
    ];

    beforePlayVideo(video) {
        // if (localStorage.getItem('isSubmitted') !== 'true') {
        //     video.pause();
        //     this.openBeforeQuestion(video);
        // }
    }

    openBeforeQuestion(video, data: any = {}, isNew?) {
        const title = isNew ? 'Take Survey' : 'Update Survey';
        const dialogRef: MatDialogRef<any> = this.dialog.open(BeforePopupComponent, {
            width: '1024px',
            disableClose: true,
            data: { title: title, faq: data },
        });

        dialogRef.afterClosed().subscribe((res) => {
            video.play();
            if (!res) {
                return;
            }
            // if (isNew) {
            //     // this.createSurvey(res);
            //     video.play();
            // } else {
            //     // this.updateSurvey(res);
            //     video.play();
            // }
        });
    }
}
