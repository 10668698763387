<div class="page-title-area page-banner-blog">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Resources' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Blogs</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<section class="blog-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/banner/ATOPIC-DERMATITIS.jpg" alt="Atopic Dermatitis" />
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a>{{ 'Helpful Resources on Atopic Dermatitis' | translate }}</a>
                        </h3>
                        <p>
                            {{
                                'Atopic Dermatitis is a long standing and recurring skin problem that causes dry, itchy, scaly, red skin. It frequently starts in infants but can affect children, and adults as well.'
                                    | translate
                            }}
                        </p>
                        <ul>
                            <li>
                                <a (click)="clickToViewAtopicDermatitis()"
                                    >{{ 'Click to View' | translate }} <i class="icofont-long-arrow-right"></i
                                ></a>
                            </li>
                            <!-- <li><i class="icofont-calendar"></i> 28 August 2022</li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/banner/Mental-Health-Card.jpg" alt="Blog" />
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a>{{ 'How Mental Health affects us' | translate }}</a>
                        </h3>
                        <p>
                            {{
                                'Mental Health encompasses emotional, psychological, and social well-being. It influences cognition, perception, and behavior. It also determines how an individual handles stress, interpersonal relationships, and decision-making.'
                                    | translate
                            }}
                        </p>
                        <ul>
                            <li>
                                <a (click)="clickToViewMentalHealth()"
                                    >{{ 'Click to View' | translate }} <i class="icofont-long-arrow-right"></i
                                ></a>
                            </li>
                            <!-- <li><i class="icofont-calendar"></i> 28 August 2022</li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/banner/cardiovasculardisease.jpg" alt="Blog" />
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a>{{ 'What is Cardiovascular Disease (CVD)?' | translate }}</a>
                        </h3>
                        <p>
                            {{
                                'Cardiovascular Diseases are a group of disorders of the heart and blood vessels, commonly including heart attacks and stroke.'
                                    | translate
                            }}
                        </p>
                        <ul>
                            <li>
                                <a (click)="clickToViewCardiovascularDisease()"
                                    >{{ 'Click to View' | translate }} <i class="icofont-long-arrow-right"></i
                                ></a>
                            </li>
                            <!-- <li><i class="icofont-calendar"></i> 28 August 2022</li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div
                *ngIf="showRespiratory"
                class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
            >
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/banner/respiratory.jpg" alt="Blog" />
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a>{{ 'Respiratory' | translate }}</a>
                        </h3>
                        <p>{{ 'COPD - Disease State and Risk Factors' | translate }}</p>
                        <ul>
                            <li>
                                <a (click)="clickToViewRespiratory()"
                                    >{{ 'Click to View' | translate }} <i class="icofont-long-arrow-right"></i
                                ></a>
                            </li>
                            <!-- <li><i class="icofont-calendar"></i> 28 August 2022</li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div
                *ngIf="showLiverDisease"
                class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
            >
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/banner/hepatitis.jpg" alt="Blog" />
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a>{{ 'Liver Disease' | translate }}</a>
                        </h3>
                        <p>
                            {{
                                'Liver disease refers to conditions that stop the liver from working or prevent it from functioning well.'
                                    | translate
                            }}
                        </p>
                        <ul>
                            <li>
                                <a (click)="clickToViewLiverDisease()"
                                    >{{ 'Click to View' | translate }} <i class="icofont-long-arrow-right"></i
                                ></a>
                            </li>
                            <!-- <li><i class="icofont-calendar"></i> 28 August 2022</li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div
                *ngIf="showPain"
                class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
            >
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/banner/pain.png" alt="Pain" />
                    </div>

                    <div class="blog-bottom">
                        <h3>
                            <a>{{ 'Pain' | translate }}</a>
                        </h3>
                        <p>
                            {{ 'Pain is an uncomfortable feeling that tells you something may be wrong.' | translate }}
                        </p>
                        <ul>
                            <li>
                                <a (click)="clickToViewPain()"
                                    >{{ 'Click to View' | translate }} <i class="icofont-long-arrow-right"></i
                                ></a>
                            </li>
                            <!-- <li><i class="icofont-calendar"></i> 28 August 2022</li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-modules-cards></app-modules-cards>
