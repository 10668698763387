import { Component } from "@angular/core";

@Component({
    selector: "app-faq-hepatitis",
    templateUrl: "./faq-hepatitis.component.html",
    styleUrls: ["./faq-hepatitis.component.scss"],
})
export class FaqHepatitisComponent {
    constructor() {}
}
