import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from '../components/language/language.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageGuard implements CanActivate {
    constructor(private languageService: LanguageService) {
        console.log('guard');
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        console.log('guard');
        const lang = next.params.lang;
        console.log(lang);
        if (lang) {
            this.languageService.setLanguage(lang);
        }
        return true;
    }
}
