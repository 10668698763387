<section class="speciality-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-12">
                <div>
                    <div class="section-title-two">
                        <span>{{ 'Resources' | translate }} - {{ 'Cardiovascular Disease' | translate }} </span>
                        <h2>{{ 'Links' | translate }}</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'US' | translate }}</h3>
                                    <p>{{ 'American Heart Association' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.heart.org/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4 wow animate__animated animate__fadeInUp">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'US' | translate }}</h3>
                                    <p>{{ 'World Heart Federation' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://world-heart-federation.org/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'US' | translate }}</h3>
                                    <p>{{ 'American College of Cardiology' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.acc.org/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4 wow animate__animated animate__fadeInUp">
                                <div class="speciality-inner">
                                    <h3>{{ 'India' | translate }}</h3>
                                    <p>{{ 'Indian Heart Association' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="http://indianheartassociation.org/heart-healthy-behaviors/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <h3>{{ 'Singapore' | translate }}</h3>
                                    <p>{{ 'Singapore Heart Foundation' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.myheart.org.sg/health/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <h3>{{ 'Canada' | translate }}</h3>
                                    <p>{{ 'HeartLife Foundation' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://heartlife.ca/patients/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <h3>{{ 'Brazil' | translate }}</h3>
                                    <p>
                                        {{
                                            'ABRAF – Brazilian Pulmonary Hypertension and Related Diseases Association'
                                                | translate
                                        }}
                                    </p>
                                    <div class="button-tray">
                                        <a
                                            href="https://abraf-ong.translate.goog/o-que-e-hipertensao-pulmonar/?_x_tr_sl=pt&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=sc"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <h3>{{ 'South Africa' | translate }}</h3>
                                    <p>{{ 'The Heart and Stroke Foundation South Africa' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.heartfoundation.co.za/resources/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <h3>CDC</h3>
                                    <p>{{ 'Deep Vein Thrombosis' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.cdc.gov/ncbddd/dvt/facts.html"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <h3>{{ 'US' | translate }}</h3>
                                    <p>{{ 'Clearing the clot' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.clearingtheclot.com/about-dvt-blood-clots/resources.html"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <div class="speciality-inner">
                                    <h3>{{ 'US' | translate }}</h3>
                                    <p>{{ 'World Thrombosis Day' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.worldthrombosisday.org/issue/thrombosis/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div>
    <h2>{{ 'Disclaimer' | translate }}</h2>
    <p>
        {{
            'The website and content are for educational purposes only and do not reflect any diagnosis or treatment specific to your condition. For queries regarding your condition, please consult a qualified medical professional.'
                | translate
        }}'
    </p>
</div>

<div class="card-footer">
    <button class="btn btn-danger mr-1" (click)="close()">{{ 'Close' | translate }}</button>
</div>
