import { Component } from '@angular/core';

@Component({
    selector: 'app-support-group-liver-disease',
    templateUrl: './support-group-liver-disease.component.html',
    styleUrls: ['./support-group-liver-disease.component.scss'],
})
export class SupportGroupLiverDiseaseComponent {
    constructor() {}
}
