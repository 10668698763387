<section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two"></div>

        <div class="row">
            <app-modules-card
                *ngFor="let topic of topics"
                class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
                [topic]="topic.topic"
                [topicText]="topic.text"
                [icon]="topic.icon"
                [topicLink]="topic.link"
            ></app-modules-card>
        </div>
    </div>
</section>
