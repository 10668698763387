import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import { ResourceUrl } from 'src/app/models/resource.model';

interface Resource {
    title: string;
    organization: string;
    url: string;
}

@Component({
    selector: 'app-resources-liver-disease',
    templateUrl: './resources-liver-disease.component.html',
    styleUrls: ['./resources-liver-disease.component.scss'],
})
export class ResourcesLiverDiseaseComponent {
    constructor(public dialogRef: MatDialogRef<ResourcesLiverDiseaseComponent>) {}

    resources: Resource[] = [
        {
            title: 'US',
            organization: 'Liver Disease - MedlinePlus',
            url: 'https://medlineplus.gov/liverdiseases.html#:~:text=There%20are%20many%20kinds%20of,fatty%20liver%20disease%20and%20cirrhosis.',
        },
        {
            title: 'US',
            organization: 'Liver problems - Symptoms and causes - Mayo Clinic',
            url: 'https://www.mayoclinic.org/diseases-conditions/liver-problems/symptoms-causes/syc-20374502',
        },
        {
            title: 'US',
            organization: 'The Stages of Liver Disease - American Liver Foundation',
            url: 'https://liverfoundation.org/about-your-liver/how-liver-diseases-progress/',
        },
        {
            title: 'UK',
            organization: 'Liver disease - NHS',
            url: 'https://www.nhs.uk/conditions/liver-disease/',
        },
        {
            title: 'US',
            organization: 'Liver Disease - NIDDK',
            url: 'https://www.niddk.nih.gov/health-information/liver-disease',
        },
        {
            title: 'US',
            organization: 'Liver Disease: Types, Causes, Symptoms and Treatment',
            url: 'https://my.clevelandclinic.org/health/diseases/17179-liver-disease',
        },
        {
            title: 'US',
            organization: 'Liver Disease: Types of Liver Problems, Causes, and More',
            url: 'https://www.healthline.com/health/liver-diseases',
        },
        {
            title: 'US',
            organization: 'Chronic Liver Disease/Cirrhosis - Johns Hopkins Medicine',
            url: 'https://www.hopkinsmedicine.org/health/conditions-and-diseases/chronic-liver-disease-cirrhosis',
        },
        // {
        //     title: 'UAE',
        //     organization: 'Emirates Thoracic Society',
        //     url: 'https://www.etsociety.ae/',
        // },
        // {
        //     title: 'Europe',
        //     organization: 'European Respiratory Society',
        //     url: 'https://www.ersnet.org/',
        // },
        // {
        //     title: 'Saudi Arabia',
        //     organization: 'Saudi Thoracic Society',
        //     url: 'https://saudithoracicsociety.org/',
        // },
        // {
        //     title: 'New Zealand',
        //     organization: 'Asthma New Zealand',
        //     url: 'https://www.asthma.org.nz/pages/resources',
        // },
        // {
        //     title: 'New Zealand',
        //     organization: 'Health Navigator',
        //     url: 'https://www.healthnavigator.org.nz/health-a-z/r/liver-diseases/',
        // },
        // {
        //     title: 'Malaysia',
        //     organization: 'Lung Foundation',
        //     url: 'http://www.lfm.org.my',
        // },
        // {
        //     title: 'South Africa',
        //     organization: 'COPD Foundation South Africa',
        //     url: 'https://www.copd.co.za/competition.php',
        // },
        // {
        //     title: 'China',
        //     organization: 'West China School of Medicine/West China Hospital of Sichuan University (WCSM/WCH)',
        //     url: 'https://www.wchscu.cn/details/54364.html',
        // },
    ];

    close() {
        this.dialogRef.close();
    }
}
