import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../language/language.service';

@Component({
    selector: 'app-patient-messages',
    templateUrl: './patient-messages.component.html',
    styleUrls: ['./patient-messages.component.scss'],
})
export class PatientMessagesComponent implements OnInit {
    patientMessages: any[] = [
        {
            poster: 'patient-messages-video-1-poster',
            src: 'patient-messages-video-1',
            title: "Atopic Dermatitis through a mother's eyes",
            description: 'A journey of discovery and understanding Atopic Dermatitis.',
            bulletPoints: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
        },
        {
            poster: 'patient-messages-video-2-poster',
            src: 'patient-messages-video-2',
            title: "A Patient's Journey with Depression",
            description: "A real life journey through the high's and low's of depression",
            bulletPoints: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
        },
        {
            poster: 'patient-messages-video-3-poster',
            src: 'patient-messages-video-3',
            title: 'CVD: Patient’s perspective on living with CVD',
            description: 'A journey of understanding CVD and taking charge of the disease.',
            bulletPoints: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
        },
        {
            poster: 'patient-messages-video-4-poster',
            src: 'patient-messages-video-4',
            title: "Patient Video. Cystic Fibrosis – A Patient's Reality",
            description: "Living and managing Cystic Fibrosis – a patient's journey",
            bulletPoints: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
        },
        {
            poster: 'patient-messages-video-5-poster',
            src: 'patient-messages-video-5',
            title: 'Prof Mobasheri shares his story on living with Osteoarthritis.',
            description: 'Osteoarthritis through a healthcare professional’s eyes.',
            bulletPoints: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
        },
        {
            poster: 'patient-messages-video-6-poster',
            src: 'patient-messages-video-6',
            title: 'Hypertension and chronic renal failure part 1',
            description: 'Listen to John as he takes you through his experiences of living with chronic diseases.',
            bulletPoints: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
        },
        {
            poster: 'patient-messages-video-7-poster',
            src: 'patient-messages-video-7',
            title: 'Hypertension and chronic renal failure part 2',
            description: 'John’s advice on importance of taking your medication.',
            bulletPoints: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
        },
    ];

    constructor(private languageService: LanguageService) {}

    ngOnInit() {
        this.languageService.showRespiratory$.subscribe((showRespiratory) => {
            if (!showRespiratory) {
                this.patientMessages = this.patientMessages.filter((topic) => topic.src !== 'patient-messages-video-4');
            }
        });
        this.languageService.showPain$.subscribe((showPain) => {
            if (!showPain) {
                this.patientMessages = this.patientMessages.filter((topic) => topic.src !== 'patient-messages-video-5');
            }
        });
    }
}
