<div class="page-title-area page-banner-faq">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Frequently Asked Questions' | translate }} - {{ 'Hepatitis' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Faq</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Hepatitis</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>What is Hepatitis?</a>
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam
                                magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae
                                illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet
                                consectetur adipisicing elit. Ut fugiat, expedita vero et quia saepe debitis
                                exercitationem labore consequatur fuga ab, laudantium sed maiores velit reiciendis.
                                Ullam illo laborum sequi.
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>What is a Cardiothoracic surgeon?</a>
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam
                                magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae
                                illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet
                                consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia
                                tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus
                                unde minima repellendus laboriosam.
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>How does a stress test work?</a>
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam
                                magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae
                                illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet
                                consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia
                                tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus
                                unde minima repellendus laboriosam.
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>Are cardiopulmonary problems hereditary?</a>
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam
                                magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae
                                illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet
                                consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia
                                tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus
                                unde minima repellendus laboriosam.
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>Do cardiac tests hurt?</a>
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam
                                magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae
                                illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet
                                consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia
                                tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus
                                unde minima repellendus laboriosam.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
