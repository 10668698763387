import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { LanguageService } from './components/language/language.service';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class LanguageResolver implements Resolve<void> {
    constructor(private languageService: LanguageService, private router: Router, private location: Location) {}

    resolve(route: ActivatedRouteSnapshot) {
        let lang = route.queryParamMap.get('lang');

        this.languageService.setLanguage(lang);
        const url = this.router.url.split('?')[0]; // remove query params
        // console.log('url', url);
        // this.router.navigate();
        // this.location.replaceState('url');
    }
}
