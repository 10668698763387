import { Component } from '@angular/core';
import { Video } from 'src/app/models/video.model';

@Component({
    selector: 'app-ophthalmology',
    templateUrl: './ophthalmology.component.html',
    styleUrls: ['./ophthalmology.component.scss'],
})
export class OphthalmologyComponent {
    constructor() {}

    videos: Video[] = [
        {
            topic: 'Open Angle Glaucoma Diagnosis video',
            text: 'To find out how Open angle glaucoma is diagnosed watch this video.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '5.08',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/ophthalmology%2FOpen%20angle%20glaucoma%20diagnosis%20video%205Sept24.mp4?alt=media&token=05eb4dbd-9503-43b8-a8e6-cf5cc5ef65c9',
            poster: 'assets/img/ophthalmology/1.png',
            videoNumber: 1,
        },
        {
            topic: 'Open Angle Glaucoma Risk Factor and Treatment video',
            text: 'Watch this video understand the Risk Factors and Treatment of Open angle glaucoma.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '5.40',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/ophthalmology%2FOpen%20angle%20glaucoma%20risk%20factor%20and%20treatment%20video%205Sept24.mp4?alt=media&token=f70f5f07-662f-4269-a66c-618d59c36be6',
            poster: 'assets/img/ophthalmology/2.png',
            videoNumber: 2,
        },
        {
            topic: 'Eye drop Instillation Video',
            text: 'To find out best way to use the eye drops for Glaucoma watch this video.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '3.49',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/ophthalmology%2FEye%20drop%20administration%20video%205Sept24.mp4?alt=media&token=22cda363-377a-48fa-898e-2fa6bf1dd7fa',
            poster: 'assets/img/ophthalmology/3.png',
            videoNumber: 3,
        },
        {
            topic: 'Glaucoma Podcast',
            text: 'Listen to experts in their field talk about Glaucoma',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '46.51',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/ophthalmology%2F4.PODCAST%20GLAUCOMA.mp4?alt=media&token=cf63718f-1e2e-4b33-b6fa-67a937e0ac28',
            poster: 'assets/img/ophthalmology/4.png',
            videoNumber: 4,
        },
    ];
}
