<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-xl-12">
            <div class="row">
                <div class="col-sm-12">
                    <form [formGroup]="itemForm">
                        <div class="card">
                            <div class="card-header">
                                <span>
                                    {{
                                        'Thank you for watching the video. Please answer three short questions before you proceed to the next video. We appreciate your time for the responses. The responses will help us improve the quality of the videos and make them more informative.'
                                            | translate
                                    }}</span
                                >
                            </div>
                            <div class="card-body">
                                <section class="theme-form">
                                    <div class="form-group">
                                        <label class="col-form-label">
                                            1. {{ 'Atopic Dermatitis is triggered by' | translate }}
                                        </label>
                                        <select class="form-control" formControlName="question1" id="question1">
                                            <option value=""></option>
                                            <option>{{ 'Cold or dry environments' | translate }}</option>
                                            <option>{{ 'Sweating' | translate }}</option>
                                            <option>{{ 'Emotional stress' | translate }}</option>
                                            <option>{{ 'Rapid temperature changes' | translate }}</option>
                                            <option>{{ 'All of the above' | translate }}</option>
                                            <option>{{ 'None of the above' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question1'].hasError('required') &&
                                                itemForm.controls['question1'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label"
                                            >2.
                                            {{
                                                'What % of children suffer from Atopic Dermatitis worldwide?'
                                                    | translate
                                            }}</label
                                        >
                                        <select class="form-control" formControlName="question2" id="question3">
                                            <option value=""></option>
                                            <option>20%</option>
                                            <option>40%</option>
                                            <option>60%</option>
                                            <option>5%</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question2'].hasError('required') &&
                                                itemForm.controls['question2'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label"
                                            >3.
                                            {{ 'Family history plays a role in Atopic Dermatitis' | translate }}</label
                                        >
                                        <select class="form-control" formControlName="question3" id="question3">
                                            <option value=""></option>
                                            <option>{{ 'Yes' | translate }}</option>
                                            <option>{{ 'No' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question3'].hasError('required') &&
                                                itemForm.controls['question3'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">
                                            {{
                                                'If you’re interested to receive more educational information and videos relating to medical conditions, please enter your e-mail below'
                                                    | translate
                                            }}
                                        </label>
                                        <input
                                            class="form-control"
                                            type="email"
                                            formControlName="email"
                                            placeholder="{{ 'Type your email address here to subscribe' | translate }}"
                                        />
                                    </div>
                                </section>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-danger mr-1" (click)="cancel()">
                                    {{ 'Cancel' | translate }}
                                </button>
                                <button class="btn btn-primary mr-1" [disabled]="itemForm.invalid" (click)="submit()">
                                    {{ 'Submit' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
