import { Injectable } from '@angular/core';

import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { AtopicDermatitisAfterVideo2 } from '../models/atopic-dermatitis-after-video-2.model';

@Injectable({
    providedIn: 'root',
})
export class AtopicDermatitisAfterVideo2Service {
    private collectionPath = 'atopicDermatitisAfterVideo2';
    atopicDermatitisAfterVideo2s$: Observable<AtopicDermatitisAfterVideo2[]>;

    constructor(private afs: AngularFirestore) {}

    getAtopicDermatitisAfterVideo2s$(pageNumber = 0, pageSize = 100): Observable<AtopicDermatitisAfterVideo2[]> {
        return this.afs
            .collection<AtopicDermatitisAfterVideo2>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('lastName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    saveAtopicDermatitisAfterVideo2(atopicDermatitisAfterVideo2: AtopicDermatitisAfterVideo2) {
        if (atopicDermatitisAfterVideo2.id) {
            return this.updateAtopicDermatitisAfterVideo2(atopicDermatitisAfterVideo2);
        }
        return this.createAtopicDermatitisAfterVideo2(atopicDermatitisAfterVideo2);
    }

    private createAtopicDermatitisAfterVideo2(atopicDermatitisAfterVideo2: AtopicDermatitisAfterVideo2) {
        atopicDermatitisAfterVideo2.createdOn = new Date();
        return this.afs.collection(this.collectionPath).add(atopicDermatitisAfterVideo2);
    }

    private updateAtopicDermatitisAfterVideo2(atopicDermatitisAfterVideo2: AtopicDermatitisAfterVideo2) {
        return this.afs
            .doc(`${this.collectionPath}/` + atopicDermatitisAfterVideo2.id)
            .update(atopicDermatitisAfterVideo2);
    }

    deleteAtopicDermatitisAfterVideo2(id: string) {
        this.afs.doc(`${this.collectionPath}/` + id).delete();
    }
}
