<div class="page-title-area page-banner-support-groups">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Support groups' | translate }} - {{ 'Liver Disease' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >{{ 'Modules' | translate }}</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="map">
    <img src="assets/img/map.png" alt="Map" />
</div> -->

<section class="map">
    <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
                <!-- partial:index.partial.html -->
                <div class="distribution-map">
                    <img src="assets/img/map_brown.png" alt="" />

                    <button class="map-point" style="top: 28%; left: 46%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'UK' | translate }}</h3>
                                <p>{{ 'British Liver Trust' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://britishlivertrust.org.uk/information-and-support/support-for-you/support-groups/"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 31%; left: 18.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Canada' | translate }}</h3>
                                <p>{{ 'PSG-華人乙型肝炎互助團體' | translate }}</p>
                                <a target="_blank" href="https://chineseliversupportgroup.ca/3496-2/about-us-2/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 39.5%; left: 18.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'US' | translate }}</h3>
                                <p>{{ 'American Liver Foundation' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://liverfoundation.org/resource-center/online-support-groups/#:~:text=The%20ALF%20Inspire%20community%20offers,%2C%20liver%20transplants%2C%20and%20more."
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 76%; left: 81%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Australia' | translate }}</h3>
                                <p>{{ 'Where To Get Support - Liver Foundation' | translate }}</p>
                                <a target="_blank" href="https://liver.org.au/living-well/where-to-get-support/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 51%; left: 68%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'India' | translate }}</h3>
                                <p>{{ 'The Liver Foundation' | translate }}</p>
                                <a target="_blank" href="https://theliverfoundationindia.org/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 42%; left: 85%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Japan' | translate }}</h3>
                                <p>
                                    {{ 'Medical Support Team | OKAYAMA UNIV. HOSPITAL' | translate }}
                                </p>
                                <a target="_blank" href="https://www.okayama-u.ac.jp/user/hospital/en/index209.html">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 82%; left: 14.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Global' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://nash-now.org/nash-support-group/"
                                    >{{ 'Click here to open' | translate }}
                                </a>
                            </div>
                        </div>
                    </button>
                </div>

                <!-- partial -->
            </div>
        </div>
    </div>
</section>

<!-- <section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <google-chart
                    #chart
                    [width]="width"
                    resolution="continents"
                    [type]="type"
                    [data]="data"
                    [options]="options"
                >
                </google-chart>
            </div>
        </div>
    </div>
</section> -->

<app-modules-cards></app-modules-cards>
