<div class="row m-0 align-items-center pb-70">
    <div class="col-lg-6 p-0">
        <div class="video-card">
            <video
                class="col-lg-10 video-card video-border"
                id="player"
                controls
                controlsList="nodownload"
                oncontextmenu="return false;"
                [poster]="poster | translate"
            >
                <source [src]="src | translate" type="video/mp4" />
            </video>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="hospital-item hospital-right">
            <h2>{{ title | translate }}</h2>
            <p>{{ description | translate }}</p>
            <ul>
                <li *ngFor="let bulletPoint of bulletPoints">
                    <i class="icofont-check-circled"></i>
                    {{ bulletPoint | translate }}
                </li>
            </ul>
            <!-- <a  class="hospital-btn" routerLink="/about">Know More</a> -->
        </div>
    </div>
</div>
