<div class="page-title-area page-banner-support-groups">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>'{{ 'Support groups' | translate }} - {{ 'Atopic Dermatitis' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >{{ 'Modules' | translate }}</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="map">
    <img src="assets/img/map.png" alt="Map" />
</div> -->

<section class="map">
    <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
                <!-- partial:index.partial.html -->
                <div class="distribution-map">
                    <img src="assets/img/map_teal.png" alt="" />

                    <button class="map-point" style="top: 75%; left: 53%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'South Africa' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.allergyfoundation.co.za/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                    <button class="map-point" style="top: 33%; left: 44%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'England' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://eczema.org/">{{ 'Click here to open' | translate }}</a>
                            </div>
                        </div>
                    </button>
                    <button class="map-point" style="top: 48%; left: 70%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Asia' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" [href]="'asia-support-link' | translate">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                    <button class="map-point" style="top: 76.5%; left: 82%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Australia' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.eczema.org.au/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 82%; left: 14.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Global' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://www.altogethereczema.org/your-community/find-your-patient-association"
                                    >{{ 'Click here to open' | translate }}
                                </a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 42%; left: 14%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'America' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://nationaleczema.org/get-support/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 26%; left: 19%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Canada' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.eczemahelp.ca/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 37%; left: 47%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'France' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.associationeczema.fr/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 32%; left: 50%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Netherlands' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.vmce.nl/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 28.5%; left: 46.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Scotland' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.eos.org.uk/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 60%; left: 74%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Singapore' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://www.nsc.com.sg/patients-and-visitors/Visitor-Information/Pages/{{
                                        'Support' | translate
                                    }}-Groups.aspx"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>
                </div>

                <!-- partial -->
            </div>
        </div>
    </div>
</section>

<!-- <section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <google-chart
                    #chart
                    [width]="width"
                    resolution="continents"
                    [type]="type"
                    [data]="data"
                    [options]="options"
                >
                </google-chart>
            </div>
        </div>
    </div>
</section> -->

<app-modules-cards></app-modules-cards>
