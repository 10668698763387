import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResourcesAtopicDermatitisComponent } from './resources-atopic-dermatitis/resources-atopic-dermatitis.component';
import { ResourcesCardiovascularDiseaseComponent } from './resources-cardiovasular-disease/resources-cardiovascular-disease.component';
import { ResourcesMentalHealthComponent } from './resources-mental-health/resources-mental-health.component';
import { ResourcesRespiratoryDiseaseComponent } from './resources-respiratory-disease/resources-respiratory-disease.component';
import { LanguageService } from '../../language/language.service';
import { ResourcesLiverDiseaseComponent } from './resources-liver-disease/resources-liver-disease.component';
import { ResourcesPainComponent } from './resources-pain/resources-pain.component';

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent {
    showRespiratory = false;
    showLiverDisease = false;
    showPain = false;

    constructor(private dialog: MatDialog, private languageService: LanguageService) {
        this.languageService.showRespiratory$.subscribe((visible) => {
            this.showRespiratory = visible;
        });
        this.languageService.showLiverDisease$.subscribe((visible) => {
            this.showLiverDisease = visible;
        });
        this.languageService.showPain$.subscribe((visible) => {
            this.showPain = visible;
        });
    }

    clickToViewAtopicDermatitis() {
        const dialogRef: MatDialogRef<any> = this.dialog.open(ResourcesAtopicDermatitisComponent, {
            width: '90%',
            disableClose: false,
            autoFocus: false,
            data: {},
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }

    clickToViewMentalHealth() {
        const dialogRef: MatDialogRef<any> = this.dialog.open(ResourcesMentalHealthComponent, {
            width: '90%',
            disableClose: false,
            autoFocus: false,
            data: {},
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }

    clickToViewCardiovascularDisease() {
        const dialogRef: MatDialogRef<any> = this.dialog.open(ResourcesCardiovascularDiseaseComponent, {
            width: '90%',
            disableClose: false,
            autoFocus: false,

            data: {},
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }

    clickToViewRespiratory() {
        const dialogRef: MatDialogRef<any> = this.dialog.open(ResourcesRespiratoryDiseaseComponent, {
            width: '90%',
            disableClose: false,
            autoFocus: false,

            data: {},
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }

    clickToViewLiverDisease() {
        const dialogRef: MatDialogRef<any> = this.dialog.open(ResourcesLiverDiseaseComponent, {
            width: '90%',
            disableClose: false,
            autoFocus: false,

            data: {},
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }

    clickToViewPain() {
        const dialogRef: MatDialogRef<any> = this.dialog.open(ResourcesPainComponent, {
            width: '90%',
            disableClose: false,
            autoFocus: false,

            data: {},
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }
}
