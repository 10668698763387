import { Injectable } from '@angular/core';

import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { AtopicDermatitisAfterVideo3 } from '../models/atopic-dermatitis-after-video-3.model';

@Injectable({
    providedIn: 'root',
})
export class AtopicDermatitisAfterVideo3Service {
    private collectionPath = 'atopicDermatitisAfterVideo3';
    atopicDermatitisAfterVideo3s$: Observable<AtopicDermatitisAfterVideo3[]>;

    constructor(private afs: AngularFirestore) {}

    getAtopicDermatitisAfterVideo3s$(pageNumber = 0, pageSize = 100): Observable<AtopicDermatitisAfterVideo3[]> {
        return this.afs
            .collection<AtopicDermatitisAfterVideo3>(this.collectionPath, (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('lastName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    saveAtopicDermatitisAfterVideo3(atopicDermatitisAfterVideo3: AtopicDermatitisAfterVideo3) {
        if (atopicDermatitisAfterVideo3.id) {
            return this.updateAtopicDermatitisAfterVideo3(atopicDermatitisAfterVideo3);
        }
        return this.createAtopicDermatitisAfterVideo3(atopicDermatitisAfterVideo3);
    }

    private createAtopicDermatitisAfterVideo3(atopicDermatitisAfterVideo3: AtopicDermatitisAfterVideo3) {
        atopicDermatitisAfterVideo3.createdOn = new Date();
        return this.afs.collection(this.collectionPath).add(atopicDermatitisAfterVideo3);
    }

    private updateAtopicDermatitisAfterVideo3(atopicDermatitisAfterVideo3: AtopicDermatitisAfterVideo3) {
        return this.afs
            .doc(`${this.collectionPath}/` + atopicDermatitisAfterVideo3.id)
            .update(atopicDermatitisAfterVideo3);
    }

    deleteAtopicDermatitisAfterVideo3(id: string) {
        this.afs.doc(`${this.collectionPath}/` + id).delete();
    }
}
