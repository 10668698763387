import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { KnowMoreComponent } from './about-know-more/know-more.component';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
    constructor(private dialog: MatDialog) {}

    knowMore() {
        const dialogRef: MatDialogRef<any> = this.dialog.open(KnowMoreComponent, {
            width: '1024px',
            disableClose: false,
            autoFocus: false,
            data: {},
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }
}
