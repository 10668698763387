import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { AtopicDermatitisBefore } from 'src/app/models/atopic-dermatitis-before.model';
import { AtopicDermatitisBeforeService } from 'src/app/services/atopicDermatitisBefore.service';

@Component({
    selector: 'app-before-popup',
    templateUrl: './before-popup.component.html',
    styleUrls: ['./before-popup.component.scss'],
})
export class BeforePopupComponent {
    public atopicDermatitisBefore: AtopicDermatitisBefore;
    // hadAtopicDermatitis = true;

    itemForm = this.fb.group({
        // id: item.id,
        hadAtopicDermatitis: ['no'],
        question1: ['', Validators.required],
        question2: ['', Validators.required],
        question3: ['', Validators.required],
        question4: ['', Validators.required],
        question5: ['', Validators.required],
        question6: ['', Validators.required],
        question7: ['', Validators.required],
        question8: ['', Validators.required],
        question9: ['', Validators.required],
        question10: ['', Validators.required],
        email: [''],
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<BeforePopupComponent>,
        private fb: FormBuilder,
        private atopicDermatitisBeforeService: AtopicDermatitisBeforeService
    ) {}

    onAtopicDermatitisChange(value) {
        this.itemForm.patchValue({
            hadAtopicDermatitis: value,
        });
    }

    // saveFaq() {
    //     this.faqService.updateFaq(this.faq);
    // }

    submit() {
        // this.isSubmitted = true;
        localStorage.setItem('isSubmitted', 'true');
        const a = { ...this.atopicDermatitisBefore, ...this.itemForm.value };
        this.atopicDermatitisBeforeService.saveAtopicDermatitisBefore(a);
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }

    submitForm(form: NgForm) {
        // this.isSubmitted = true;
        if (!form.valid) {
            return false;
        } else {
            alert(JSON.stringify(form.value));
        }
        this.dialogRef.close();
    }

    get hadAtopicDermatitis() {
        return this.itemForm.controls.hadAtopicDermatitis.value;
    }
}
