<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-xl-12">
            <div class="row">
                <div class="col-sm-12">
                    <form [formGroup]="itemForm">
                        <div class="card">
                            <div class="card-header">
                                <span>
                                    {{
                                        'Thank you for watching the video. Please answer three short questions before you proceed to the next video. We appreciate your time for the responses. The responses will help us improve the quality of the videos and make them more informative.'
                                            | translate
                                    }}
                                </span>
                            </div>
                            <div class="card-body">
                                <section class="theme-form">
                                    <div class="form-group">
                                        <label class="col-form-label">
                                            {{
                                                '1. Infants with Atopic Dermatitis, as they get older, may develop other allergic diseases such as asthma, allergic rhinitis and food allergy.'
                                                    | translate
                                            }}
                                        </label>
                                        <select class="form-control" formControlName="question1" id="question1">
                                            <option value=""></option>
                                            <option>{{ 'True' | translate }}</option>
                                            <option>{{ 'False' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question1'].hasError('required') &&
                                                itemForm.controls['question1'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '2. Can Atopic Dermatitis be cured?' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question2" id="question3">
                                            <option value=""></option>
                                            <option>{{ 'Yes' | translate }}</option>
                                            <option>{{ 'No, but symptoms can be controlled' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question2'].hasError('required') &&
                                                itemForm.controls['question2'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '3. Precautions to manage Atopic Dermatitis are' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question3" id="question3">
                                            <option value=""></option>
                                            <option>{{ 'Eliminating trigger factors' | translate }}</option>
                                            <option>
                                                {{ 'Maintaining hydration with moisturizer and bathing' | translate }}
                                            </option>
                                            <option>
                                                {{
                                                    'Treating skin irritation and relieving itching with medications'
                                                        | translate
                                                }}
                                            </option>
                                            <option>{{ 'All of the above' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question3'].hasError('required') &&
                                                itemForm.controls['question3'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            'If you’re interested to receive more educational information and videos relating to medical conditions, please enter your e-mail below'
                                                | translate
                                        }}</label>
                                        <input
                                            class="form-control"
                                            type="email"
                                            formControlName="email"
                                            placeholder="{{ 'Type your email address here' | translate }}"
                                        />
                                    </div>
                                </section>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-danger mr-1" (click)="cancel()">
                                    {{ 'Cancel' | translate }}
                                </button>
                                <button class="btn btn-primary mr-1" [disabled]="itemForm.invalid" (click)="submit()">
                                    {{ 'Submit' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
