import { Component } from "@angular/core";

@Component({
    selector: "app-faq-oncology",
    templateUrl: "./faq-oncology.component.html",
    styleUrls: ["./faq-oncology.component.scss"],
})
export class FaqOncologyComponent {
    constructor() {}
}
