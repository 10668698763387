<div class="page-title-area page-title-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Links' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Contact us</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<!-- <div class="location-area">
    <div class="container">
        <div class="row location-wrap">


            <div class="col-sm-6 col-lg-6">
                <div class="location-item">
                    <i class="icofont-ui-message"></i>
                    <h3 >Email</h3>
                    <ul>
                        <li>hello@patient-academy.com</li>
                        <li>emailexample@name.com</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-6">
                <div class="location-item">
                    <i class="icofont-ui-call"></i>
                    <h3 >Phone</h3>
                    <ul>
                        <li>+27 11 3332 322</li>
                        <li>+27 800 3332 322</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="drop-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 p-0">
                <div class="drop-item drop-img">
                    <div class="drop-left">
                        <h2 >Drop your message for any info or question.</h2>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            class="form-control"
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            class="form-control"
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="phone_number"
                                            class="form-control"
                                            placeholder="Phone"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="msg_subject"
                                            class="form-control"
                                            placeholder="Subject"
                                        />
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <textarea
                                            name="message"
                                            class="form-control"
                                            cols="30"
                                            rows="5"
                                            placeholder="Your Message"
                                        ></textarea>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12">
                                    <button type="submit" class="drop-btn">
                                        Send
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div
                    class="speciality-item speciality-right speciality-right-two speciality-right-three"
                >
                    <img src="assets/img/banner/bg4.jpg" alt="Contact" />

                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div class="contact-map-area">
    <div id="map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.72810813924!2d-0.24168018863114898!3d51.528771840455114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sbd!4v1589770854202!5m2!1sen!2sbd"
        ></iframe>
    </div>
</div> -->

<div class="container">
    <div class="section-title-two">
        <!-- <span>Services</span> -->
        <!-- <h2 >Modules</h2> -->
    </div>

    <div class="row">
        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
            <div class="service-item card-block">
                <div class="service-front2">
                    <a
                        >href="https://eczema.org/" target="_blank">
                        <i class="icofont-doctor"></i>
                        <h3>{{ 'Atopic Dermatitis' | translate }}</h3>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
            <div class="service-item card-block">
                <div class="service-front2">
                    <i class="icofont-brainstorming"></i>
                    <h3>{{ 'Mental Health' | translate }}</h3>
                    <!-- <p >
                    Mental Health encompasses emotional, psychological,
                    and social well-being.
                </p> -->
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
            <div class="service-item card-block">
                <div class="service-front2">
                    <i class="icofont-prescription"></i>
                    <h3>Hepatitis</h3>
                    <!-- <p >
                        Hepatitis refers to an inflammatory condition of the
                        liver.
                    </p> -->
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
            <div class="service-item card-block">
                <div class="service-front2">
                    <i class="icofont-lungs"></i>
                    <h3>Respiratory</h3>
                    <!-- <p >
                        The respiratory system consists of specific organs
                        and structures used for gas exchange.
                    </p> -->
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
            <div class="service-item card-block">
                <div class="service-front2">
                    <i class="icofont-thunder-light"></i>
                    <h3>Pain</h3>
                    <!-- <p >
                        Pain is an uncomfortable feeling that tells you
                        something may be wrong.
                    </p> -->
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
            <div class="service-item card-block">
                <div class="service-front2">
                    <i class="icofont-heart-beat-alt"></i>
                    <h3>{{ 'Cardiovascular Disease' | translate }}</h3>
                    <!-- <p >
                        Heart conditions that include diseased vessels,
                        structural problems and blood clots.
                    </p> -->
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
            <div class="service-item card-block">
                <div class="service-front2">
                    <i class="icofont-radio-active"></i>
                    <h3>Oncology</h3>
                    <!-- <p >
                        Oncology deals with the study, treatment, diagnosis
                        and prevention of cancer.
                    </p> -->
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
            <div class="service-item card-block">
                <div class="service-front2">
                    <i class="icofont-computer"></i>
                    <h3>Digital Health</h3>
                    <!-- <p >
                        Digital health is a discipline that includes digital
                        care programs, technologies with health.
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</div>
