<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-xl-12">
            <div class="row">
                <div class="col-sm-12">
                    <form [formGroup]="itemForm">
                        <div class="card">
                            <div class="card-header">
                                <span>{{
                                    'Thank you for watching the video. Please answer three short questions before you proceed to the next video. We appreciate your time for the responses. The responses will help us improve the quality of the videos and make them more informative.'
                                        | translate
                                }}</span>
                            </div>
                            <div class="card-body">
                                <section class="theme-form">
                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '1. The symptoms of Atopic Dermatitis are' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question1" id="question1">
                                            <option value=""></option>
                                            <option>{{ 'Intense itching' | translate }}</option>
                                            <option>{{ 'Inflamed skin' | translate }}</option>
                                            <option>{{ 'Red skin' | translate }}</option>
                                            <option>{{ 'Skin flaking' | translate }}</option>
                                            <option>{{ 'All of the above' | translate }}</option>
                                            <option>{{ 'None of the above' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question1'].hasError('required') &&
                                                itemForm.controls['question1'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{ '2. A flare up is when' | translate }}</label>
                                        <select class="form-control" formControlName="question2" id="question3">
                                            <option value=""></option>
                                            <option>{{ 'When skin condition worsens' | translate }}</option>
                                            <option>{{ 'Your skin condition improves' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question2'].hasError('required') &&
                                                itemForm.controls['question2'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '3. How is Atopic Dermatitis diagnosed?' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question3" id="question3">
                                            <option value=""></option>
                                            <option>{{ 'Medical history and symptoms' | translate }}</option>
                                            <option>{{ 'Physical examination' | translate }}</option>
                                            <option>{{ 'All of the above' | translate }}</option>
                                            <option>{{ 'None of the above' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question3'].hasError('required') &&
                                                itemForm.controls['question3'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            'If you’re interested to receive more educational information and videos relating to medical conditions, please enter your e-mail below'
                                                | translate
                                        }}</label>
                                        <input
                                            class="form-control"
                                            type="email"
                                            formControlName="email"
                                            placeholder="{{ 'Type your email address here' | translate }}"
                                        />
                                    </div>
                                </section>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-danger mr-1" (click)="cancel()">
                                    {{ 'Cancel' | translate }}
                                </button>
                                <button class="btn btn-primary mr-1" [disabled]="itemForm.invalid" (click)="submit()">
                                    {{ 'Submit' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
