<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img [src]="image" alt="" />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="" />

                    <h2>{{ topic | translate }}</h2>
                    <p>
                        {{ text | translate }}
                    </p>

                    <ul>
                        <li *ngFor="let bullet of bullets">
                            <i class="icofont-check-circled"></i> {{ bullet | translate }}
                        </li>
                    </ul>
                    <!-- <a [routerLink]="buttonUrl || null" readonly (click)="preventDefault($event)">{{
                        buttonText | translate
                    }}</a> -->
                    <!-- <a
                        [routerLink]="buttonUrl || ''"
                        (click)="preventDefault($event)"
                        [innerText]="buttonText | translate"
                    ></a> -->

                    <a href="#" (click)="handleClick($event)" [class.disabled]="buttonUrl === '#'">{{
                        buttonText | translate
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
