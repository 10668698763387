import { Component } from '@angular/core';
import { Video } from 'src/app/models/video.model';

@Component({
    selector: 'app-pain',
    templateUrl: './pain.component.html',
    styleUrls: ['./pain.component.scss'],
})
export class PainComponent {
    constructor() {}

    videos: Video[] = [
        {
            topic: 'DIABETIC PERIPHERAL NEUROPATHY (DPN) PART 1',
            text: 'Prof. Tesfaye helps you understand Diabetic Peripheral Neuropathy (DPN)',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '7.40',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/pain%2F1.PAIN%20-%20PROF%20TESFAYE%20Part%201.%2027aug23.mp4?alt=media&token=ebba190b-7fe7-490a-96b8-ccdd57e9a993',
            poster: 'assets/img/pain/1.png',
            videoNumber: 1,
        },
        {
            topic: 'DIABETIC PERIPHERAL NEUROPATHY (DPN) PART 2',
            text: 'Prof. Tesfaye speak about the symptoms of Diabetic Peripheral Neuropathy (DPN)',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '9.00',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/pain%2F2.PAIN%20-%20PROF%20TESFAYE%20Part%202.%2030Aug23%20b.mp4?alt=media&token=fdf5de46-1098-4f6d-8d47-34eb66366b18',
            poster: 'assets/img/pain/2.png',
            videoNumber: 2,
        },
        {
            topic: "OSTEOARTHRITIS - AN EXPERT'S VIEW",
            text: 'Prof. Mobasheri gives you some insights into Osteoarthtitis.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '11.11',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/pain%2F3.PAIN%20-%20PROF%20MOBASHERI%20OSTEOARTHRITIS%20AN%20EXPERTS%20VIEW%2028Aug23.mp4?alt=media&token=5a6b7a4d-410a-495b-881b-e4cdd87a4e6c',
            poster: 'assets/img/pain/3.png',
            videoNumber: 3,
        },
        {
            topic: 'FIBROMYALGIA',
            text: 'Prof. Claw helps you understand more about Fibromyalgia',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '8.51',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/pain%2F4.PAIN%20-%20FIBROMYALGIA%2027Aug23.mp4?alt=media&token=531197e5-55dd-41ea-83db-a59099fcd787',
            poster: 'assets/img/pain/4.png',
            videoNumber: 4,
        },
    ];
}
