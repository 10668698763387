import { Component } from '@angular/core';

@Component({
    selector: 'app-modules',
    templateUrl: './modules.component.html',
    styleUrls: ['./modules.component.scss'],
})
export class ModulesComponent {
    constructor() {}
}
