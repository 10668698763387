import { Component } from "@angular/core";

@Component({
    selector: "app-doctor",
    templateUrl: "./doctor.component.html",
    styleUrls: ["./doctor.component.scss"],
})
export class DoctorComponent {
    constructor() {}
}
