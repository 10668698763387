import { Component } from "@angular/core";

@Component({
    selector: "app-faq-cardiovascular",
    templateUrl: "./faq-cardiovascular.component.html",
    styleUrls: ["./faq-cardiovascular.component.scss"],
})
export class FaqCardiovascularComponent {
    constructor() {}
}
