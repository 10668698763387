import { Component } from "@angular/core";

@Component({
    selector: "app-faq-liver-disease",
    templateUrl: "./faq-liver-disease.component.html",
    styleUrls: ["./faq-liver-disease.component.scss"],
})
export class FaqLiverDiseaseComponent {
    constructor() {}
}
