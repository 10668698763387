<div class="page-title-area page-banner-support-groups">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Support groups' | translate }} - {{ 'Respiratory' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >{{ 'Modules' | translate }}</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="map">
    <img src="assets/img/map.png" alt="Map" />
</div> -->

<section class="map">
    <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
                <!-- partial:index.partial.html -->
                <div class="distribution-map">
                    <img src="assets/img/map_orange.png" alt="" />

                    <button class="map-point" style="top: 41%; left: 19%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'US' | translate }}</h3>
                                <h6>{{ 'American Lung Association' | translate }}</h6>
                                <p>{{ 'Support' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://www.lung.org/help-support/lung-helpline-and-tobacco-quitline"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 33%; left: 18%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Canada' | translate }}</h3>
                                <p>{{ 'Lung Sask' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://www.lungsask.ca/lungs/programs-support/lung-helpline"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 74.5%; left: 50.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'South Africa' | translate }}</h3>
                                <p>{{ 'COPD Foundation South Africa' | translate }}</p>
                                <a target="_blank" href="https://www.copd.co.za/competition.php">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 76.5%; left: 55.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'South Africa' | translate }}</h3>
                                <p>The Heart and Stroke Foundation South Africa</p>
                                <a target="_blank" href="https://heartfoundation.co.za/stop-smoking/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 87%; left: 91%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'New Zealand' | translate }}</h3>
                                <p>{{ 'Asthma New Zealand' | translate }}</p>
                                <a target="_blank" href="https://www.asthma.org.nz/pages/copd-support-groups">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 82%; left: 96%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'New Zealand' | translate }}</h3>
                                <p>{{ 'Health Navigator' | translate }}</p>
                                <a target="_blank" href="https://www.healthnavigator.org.nz/support/c/copd-support/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                </div>

                <!-- partial -->
            </div>
        </div>
    </div>
</section>

<!-- <section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <google-chart
                    #chart
                    [width]="width"
                    resolution="continents"
                    [type]="type"
                    [data]="data"
                    [options]="options"
                >
                </google-chart>
            </div>
        </div>
    </div>
</section> -->

<app-modules-cards></app-modules-cards>
