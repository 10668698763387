<div class="page-title-area page-banner-faq">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Frequently Asked Questions' | translate }} - {{ 'Pain' | translate }}</h2>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Pain</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>What is Diabetic Peripheral Neuropathy (DPN)?</a>
                            <p>Watch Video 1 to find out.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>What are the symptoms Diabetic Peripheral Neuropathy (DPN)? </a>
                            <p>Watch Video 1 to find out more.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>Can I exercise if I have Diabetic Peripheral Neuropathy (DPN)?</a>
                            <p>Watch Video 2 to find out more.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>What is Osteoarthritis (OA)? </a>
                            <p>Watch Video 3 to find out more.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>What are the symptoms of Osteoarthritis? </a>
                            <p>Watch Video 3 to find out more.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>What is Fibromyalgia? </a>
                            <p>Watch Video 4 to find out.</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>What can be done to manage Fibromyalgia? </a>
                            <p>Watch Video 4 to find out more.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
