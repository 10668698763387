<section class="speciality-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-12">
                <div>
                    <div class="section-title-two">
                        <span>{{ 'Resources' | translate }} - {{ 'Atopic Dermatitis' | translate }}</span>
                        <h2>{{ 'Links' | translate }}</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'US' | translate }}</h3>
                                    <p>{{ 'National Library of Medicine' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://medlineplus.gov/eczema.html"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'US' | translate }}</h3>
                                    <p>{{ 'American Academy of Dermatology Association' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.aad.org/public/diseases/eczema/types/atopic-dermatitis"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'Europe' | translate }}</h3>
                                    <p>{{ 'European Academy of Dermatology and Venereology' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            [href]="'American Academy of Dermatology Association-url' | translate"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <h3 >Atopic Dermatitis 101</h3>
                                    <p >National Eczema Association</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://nationaleczema.org/blog/atopic-dermatitis-101/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{'Click to Open' | translate}}</a
                                        >
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <h3 >Dermatology</h3>
                                    <p >Learning Network</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.hmpgloballearningnetwork.com/site/thederm/site/cathlab/event/patient-resources-atopic-dermatitis"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{'Click to Open' | translate}}</a
                                        >
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <h3 >Severe Atopic Dermatitis?</h3>
                                    <p >American Academy of Dermatology Association</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.aad.org/public/diseases/eczema/insider/severe-atopic-dermatitis"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{'Click to Open' | translate}}</a
                                        >
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <h3 >Online Resources</h3>
                                    <p >Atopic Dermatitis</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.worldallergy.org/resources/world-allergy/world-allergy-week-2018/atopic-dermatitis-online-resources"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{'Click to Open' | translate}}</a
                                        >
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div>
    <h2>{{ 'Disclaimer' | translate }}</h2>
    <p>
        {{
            'The website and content are for educational purposes only and do not reflect any diagnosis or treatment specific to your condition. For queries regarding your condition, please consult a qualified medical professional.'
                | translate
        }}
    </p>
</div>

<div class="card-footer">
    <button class="btn btn-danger mr-1" (click)="close()">{{ 'Close' | translate }}</button>
</div>
