import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AfterQuestionVideo1PopupComponent } from '../atopic-demermatitis/after-question-video-1/after-question-video-1-popup.component';
import { AfterQuestionVideo2PopupComponent } from '../atopic-demermatitis/after-question-video-2/after-question-video-2-popup.component';
import { AfterQuestionVideo3PopupComponent } from '../atopic-demermatitis/after-question-video-3/after-question-video-3-popup.component';
import { BeforePopupComponent } from '../atopic-demermatitis/before-question/before-popup.component';

@Component({
    selector: 'app-video-card',
    templateUrl: './video-card.component.html',
    styleUrls: ['./video-card.component.css'],
})
export class VideoCardComponent {
    @Input() topic = '';
    @Input() text = '';
    @Input() bullets = [];
    @Input() videoLength = '';
    @Input() url = '';
    @Input() poster = '';
    @Input() videoNumber;
    @Input() enableBeforeQuestions = false;
    @Input() enableAfterQuestions = false;

    constructor(private dialog: MatDialog) {}

    beforePlayVideo(video) {
        // this.configureVideo(video, this.videoNumber);
        // if (localStorage.getItem('isSubmitted') !== 'true' && this.enableBeforeQuestions) {
        //     video.pause();
        //     this.openQuestionBefore(video);
        // }
    }

    openQuestionBefore(video, data: any = {}, isNew?) {
        const title = isNew ? 'Take Survey' : 'Update Survey';
        const dialogRef: MatDialogRef<any> = this.dialog.open(BeforePopupComponent, {
            width: '1024px',
            disableClose: true,
            data: { title: title, faq: data },
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
            // if (isNew) {
            //     // this.createSurvey(res);
            //     video.play();
            // } else {
            //     // this.updateSurvey(res);
            //     video.play();
            // }
        });
    }

    openQuestionAfter(afterQuestionPopup) {
        const dialogRef: MatDialogRef<any> = this.dialog.open(afterQuestionPopup, {
            width: '1024px',
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }

    configureVideo(video, videoNumber: number) {
        if (video && this.enableAfterQuestions) {
            video.ontimeupdate = () => {
                const currentTime = video.currentTime;
                const duration = video.duration;
                if (currentTime == duration) {
                    switch (videoNumber) {
                        case 1:
                            this.openQuestionAfterVideo1();
                            break;
                        case 2:
                            this.openQuestionAfterVideo2();
                            break;
                        case 3:
                            this.openQuestionAfterVideo3();
                            break;

                        default:
                            break;
                    }
                }
            };
        }
    }

    openQuestionAfterVideo1() {
        console.log('1');
        const dialogRef: MatDialogRef<any> = this.dialog.open(AfterQuestionVideo1PopupComponent, {
            width: '1024px',
            disableClose: false,
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }

    openQuestionAfterVideo2() {
        console.log('2');
        const dialogRef: MatDialogRef<any> = this.dialog.open(AfterQuestionVideo2PopupComponent, {
            width: '1024px',
            disableClose: false,
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }

    openQuestionAfterVideo3() {
        console.log('3');
        const dialogRef: MatDialogRef<any> = this.dialog.open(AfterQuestionVideo3PopupComponent, {
            width: '1024px',
            disableClose: false,
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
        });
    }
}
