<div class="page-title-area page-banner-support-groups">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Support groups' | translate }} - {{ 'Pain' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >{{ 'Modules' | translate }}</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="map">
    <img src="assets/img/map.png" alt="Map" />
</div> -->

<section class="map">
    <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
                <!-- partial:index.partial.html -->
                <div class="distribution-map">
                    <img src="assets/img/map_green.png" alt="" />

                    <button class="map-point" style="top: 37.5%; left: 18.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'US' | translate }}</h3>
                                <p>
                                    {{
                                        'American Chronic Pain Association - National Organization for Rare Disorders'
                                            | translate
                                    }}
                                </p>
                                <a
                                    target="_blank"
                                    href="https://rarediseases.org/non-member-patient/american-chronic-pain-association/"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 28%; left: 46%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'UK' | translate }}</h3>
                                <p>
                                    {{ 'Pain UK CIO: A Charity supporting Charities who help with pain' | translate }}
                                </p>
                                <a target="_blank" href="https://painuk.org/">{{ 'Click here to open' | translate }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 53.5%; left: 58%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Dubai' | translate }}</h3>
                                <p>
                                    {{ 'Fibromyalgia Treatment in Dubai: Pain Management Clinic in Dubai' | translate }}
                                </p>
                                <a
                                    target="_blank"
                                    href="https://www.gncdubai.com/treatment/fibromyalgia-treatment-dubai/?gclid=Cj0KCQjwxuCnBhDLARIsAB-cq1pPoJbfpCnPtluwh4bbF8a6uOEnx0I_AFSKusVXbzbPNWXlMT77jlYaAoM5EALw_wcB"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 51%; left: 68%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'India' | translate }}</h3>
                                <p>
                                    {{ 'Group Therapy for Chronic Pain in Bangalore & Its Cost' | translate }}
                                </p>
                                <a
                                    target="_blank"
                                    href="https://www.cadabamshospitals.com/chronic-pain/group-therapy-bangalore/"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 76%; left: 81%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Australia' | translate }}</h3>
                                <p>{{ 'APMA' | translate }}</p>
                                <a target="_blank" href="https://www.painmanagement.org.au/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 74%; left: 55%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'South Africa' | translate }}</h3>
                                <p>{{ 'South African Pain Support Group' | translate }}</p>
                                <a target="_blank" href="https://www.psychologytoday.com/za/basics/chronic-pain">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 45.5%; left: 18.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'US' | translate }}</h3>
                                <p>
                                    {{ '6 Chronic Pain Support Groups of 2023' | translate }}
                                </p>
                                <a
                                    target="_blank"
                                    href="https://www.verywellhealth.com/about-us-5180305#toc-contact-us"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <google-chart
                    #chart
                    [width]="width"
                    resolution="continents"
                    [type]="type"
                    [data]="data"
                    [options]="options"
                >
                </google-chart>
            </div>
        </div>
    </div>
</section> -->

<app-modules-cards></app-modules-cards>
