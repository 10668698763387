import { Component } from "@angular/core";

@Component({
    selector: "app-doctor-details",
    templateUrl: "./doctor-details.component.html",
    styleUrls: ["./doctor-details.component.scss"],
})
export class DoctorDetailsComponent {
    constructor() {}
}
