import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../language/language.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    banners = [
        {
            topic: 'Atopic Dermatitis',
            text: `Atopic Dermatitis is a long standing and recurring skin problem that causes dry, itchy, scaly, red skin. It frequently starts in infants but can affect children, and adults as well.`,
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/banner/ATOPIC-DERMATITIS.jpg',
            buttonUrl: '/atopic-dermatitis',
            buttonText: 'View',
        },
        {
            topic: 'Mental Health',
            text: 'Mental Health encompasses emotional, psychological, and social well-being. It influences cognition, perception, and behavior. It also determines how an individual handles stress, interpersonal relationships, and decision-making.',
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/banner/Mental-Health-Banner.jpg',
            buttonUrl: '/mental-health',
            buttonText: 'View',
        },
        {
            topic: 'Cardiovascular Disease',
            text: `Cardiovascular Disease (CVD) is a general term for conditions affecting the heart or blood vessels. It's usually associated with a build-up of fatty deposits inside the arteries and an increased risk of blood clots.`,
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/banner/cardiovasculardisease.jpg',
            buttonUrl: '/cardiovascular-disease',
            buttonText: 'View',
        },
        {
            topic: 'Respiratory',
            text: 'The respiratory system is the network of organs and tissues that help you breathe. It includes your airways, lungs and blood vessels. The muscles that power your lungs are also part of the respiratory system. These parts work together to move oxygen throughout the body and clean out waste gases like carbon dioxide.',
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/banner/respiratory.jpg',
            buttonUrl: '/respiratory-disease',
            buttonText: 'View',
        },
        {
            topic: 'Liver Disease',
            text: 'Liver disease refers to conditions that stop the liver from working or prevent it from functioning well. It is the umbrella term used when referring to ailments which affect the liver, such as cirrhosis, hepatitis, fatty liver disease, cancer, and liver failure.',
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/banner/hepatitis.jpg',
            buttonUrl: '/liver-disease',
            buttonText: 'View',
        },
        {
            topic: 'Pain',
            text: "Pain is an uncomfortable feeling that tells you something may be wrong. It can be steady, throbbing, stabbing, aching, pinching, or described in many other ways. Sometimes, it's just a nuisance, like a mild headache. Other times it can be debilitating.",
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/banner/pain.png',
            buttonUrl: '/pain',
            buttonText: 'View',
        },

        {
            topic: 'Ophthalmology',
            text: `Ophthalmology is the field of medicine that focuses on the health of the eyes. It involves the study, diagnosis, and treatment of eye diseases and disorders.`,
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/ophthalmology/ophthalmology.jpg',
            buttonUrl: '/ophthalmology',
            buttonText: 'View',
        },
        {
            topic: 'Urology',
            text: `Urology is the branch of medicine that focuses on the diagnosis, treatment, and management of disorders related to the urinary tract in both men and women, as well as the male reproductive system`,
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/urology/urology.jpg',
            buttonUrl: '/urology',
            buttonText: 'View',
        },
        {
            topic: 'Shared decision making',
            text: `Shared decision making is a discipline that includes digital care programs, technologies with health, healthcare, living, and society to enhance the efficiency of healthcare delivery and to make medicine more personalized and precise.`,
            bullets: ['Videos', 'Resources', 'FAQs'],
            image: 'assets/img/banner/Digital-healt.jpeg',
            buttonUrl: '/shared-decision-making',
            buttonText: 'View',
        },
    ];

    constructor(public languageService: LanguageService) {}
    ngOnInit(): void {
        const language = this.languageService.getLanguage();
        console.log(language);
    }
}
