<div class="page-title-area page-banner-faq">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Frequently Asked Questions' | translate }} - {{ 'Cardiovascular' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Faq</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head"></div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>{{ 'What is Cardiovascular Disease?' | translate }}</a>
                            <p>{{ 'Watch video 1 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>{{ 'What are the Risk Factors of Cardiovascular Disease?' | translate }}</a>
                            <p>{{ 'Watch video 1 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>{{ 'What is Hypertension?' | translate }}</a>
                            <p>{{ 'Watch video 2 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>{{ 'What is normal High Blood Pressure?' | translate }}</a>
                            <p>{{ 'Watch video 2 to know your numbers.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'What is Cholesterol?' | translate }}</a>
                            <p>{{ 'Watch video 3 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'What is Dyslipidemia?' | translate }}</a>
                            <p>{{ 'Watch video 3 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'How do I manage Dyslipidemia?' | translate }}</a>
                            <p>{{ 'Watch video 4 to understand how to manage Dyslipidemia.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'What is Thrombosis and how does it impact me?' | translate }}</a>
                            <p>{{ 'Watch video 5 to learn more about Thrombosis.' | translate }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
