<section class="speciality-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-12">
                <div>
                    <div class="section-title-two">
                        <span>{{ 'Resources' | translate }} - {{ 'Mental Health' | translate }} </span>
                        <h2>{{ 'Links' | translate }}</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'Global' | translate }}</h3>
                                    <p>{{ 'The World Psychiatric Association (WPA)' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.wpanet.org/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'US' | translate }}</h3>
                                    <p>{{ '(DBSA) Depression and Bipolar Support Alliance' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.dbsalliance.org/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <!-- <i class="icofont-check-circled"></i> -->
                                    <h3>{{ 'Global' | translate }}</h3>
                                    <p>{{ 'The World Health Organisation (WHO)' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.who.int/health-topics/mental-health#tab=tab_1"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <h3>{{ 'UK' | translate }}</h3>
                                    <p>{{ 'Mental Health Foundation' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.mentalhealth.org.uk/"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <h3>{{ 'South Africa' | translate }}</h3>
                                    <p>{{ 'The South African Depression and Anxiety Group' | translate }}</p>
                                    <div class="button-tray">
                                        <a
                                            href="https://www.mhinnovation.net/organisations/south-african-depression-and-anxiety-group-sadag"
                                            target="_blank"
                                            class="btn btn-primary white-text"
                                        >
                                            {{ 'Click to Open' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div>
    <h2>{{ 'Disclaimer' | translate }}</h2>
    <p>
        {{
            'The website and content are for educational purposes only and do not reflect any diagnosis or treatment specific to your condition. For queries regarding your condition, please consult a qualified medical professional.'
                | translate
        }}'
    </p>
</div>

<div class="card-footer">
    <button class="btn btn-danger mr-1" (click)="close()">{{ 'Close' | translate }}</button>
</div>
