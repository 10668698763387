<div class="page-title-area page-banner-modules">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Modules' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Modules</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<app-modules-cards></app-modules-cards>
