<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-xl-12">
            <div class="row">
                <div class="col-sm-12">
                    <form [formGroup]="itemForm">
                        <div class="card">
                            <div class="card-header">
                                <span>
                                    {{
                                        'Hello, thank you for your interest in watching our video. Please answer the short questionnaire that will not take more than two minutes of your time. The questionnaire is being administered to understand your basic knowledge about Atopic Dermatitis:'
                                            | translate
                                    }}
                                </span>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <H5>
                                        {{
                                            'Do you or your family member have or have been diagnosed with Atopic Dermatitis?'
                                                | translate
                                        }}
                                    </H5>

                                    <mat-radio-group formControlName="hadAtopicDermatitis">
                                        <mat-radio-button value="yes">{{ 'Yes' | translate }}</mat-radio-button>
                                        <mat-radio-button value="no">{{ 'No' | translate }}</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <section *ngIf="hadAtopicDermatitis === 'yes'" class="theme-form">
                                    <div class="form-group">
                                        <label class="col-form-label">
                                            {{ '1. Atopic Dermatitis is triggered by' | translate }}
                                        </label>
                                        <select class="form-control" formControlName="question1" id="question1">
                                            <option value=""></option>
                                            <option>{{ 'Cold or dry environments' | translate }}</option>
                                            <option>{{ 'Sweating' | translate }}</option>
                                            <option>{{ 'Emotional stress' | translate }}</option>
                                            <option>{{ 'Rapid temperature changes' | translate }}</option>
                                            <option>{{ 'All of the above' | translate }}</option>
                                            <option>{{ 'None of the above' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question1'].hasError('required') &&
                                                itemForm.controls['question1'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '2. The symptoms of Atopic Dermatitis are' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question2" id="question2">
                                            <option value=""></option>
                                            <option>{{ 'Intense itching' | translate }}</option>
                                            <option>{{ 'Inflamed skin' | translate }}</option>
                                            <option>{{ 'Red skin' | translate }}</option>
                                            <option>{{ 'Skin flaking' | translate }}</option>
                                            <option>{{ 'All of the above' | translate }}</option>
                                            <option>{{ 'None of the above' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question2'].hasError('required') &&
                                                itemForm.controls['question2'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '3. What % of children suffer from Atopic Dermatitis worldwide' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question3" id="question3">
                                            <option value=""></option>
                                            <option>{{ '20%' | translate }}</option>
                                            <option>{{ '40%' | translate }}</option>
                                            <option>{{ '60%' | translate }}</option>
                                            <option>{{ '5%' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question3'].hasError('required') &&
                                                itemForm.controls['question3'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{ '4. A flare up is when' | translate }}</label>
                                        <select class="form-control" formControlName="question4" id="question4">
                                            <option value=""></option>
                                            <option>{{ 'When skin condition worsens' | translate }}</option>
                                            <option>{{ 'Your skin condition improves' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question4'].hasError('required') &&
                                                itemForm.controls['question4'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '5. Precautions to manage Atopic Dermatitis are' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question5" id="question5">
                                            <option value=""></option>
                                            <option>{{ 'Eliminating trigger factor' | translate }}</option>
                                            <option>
                                                {{ 'Maintaining hydration with moisturizer and bathing' | translate }}
                                            </option>
                                            <option>
                                                {{
                                                    'Treating skin irritation and relieving itching with medications'
                                                        | translate
                                                }}
                                            </option>
                                            <option>{{ 'All of the above' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question5'].hasError('required') &&
                                                itemForm.controls['question5'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '6. Can Atopic Dermatitis be cured' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question6" id="question6">
                                            <option value=""></option>
                                            <option>{{ 'yes' | translate }}</option>
                                            <option>{{ 'no, but symptoms can be controlled' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question6'].hasError('required') &&
                                                itemForm.controls['question6'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '7. Do you have difficulty in taking medicines' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question7" id="question7">
                                            <option value=""></option>
                                            <option>{{ 'sometimes' | translate }}</option>
                                            <option>{{ 'always' | translate }}</option>
                                            <option>{{ 'never' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question7'].hasError('required') &&
                                                itemForm.controls['question7'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">
                                            {{ '8. What causes the difficulty in taking the medicines' | translate }}
                                        </label>
                                        <select class="form-control" formControlName="question8" id="question8">
                                            <option value=""></option>
                                            <option>{{ 'forget to take' | translate }}</option>
                                            <option>{{ 'unavailable' | translate }}</option>
                                            <option>{{ 'bad taste' | translate }}</option>
                                            <option>{{ 'causes uneasiness like burning etc' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question8'].hasError('required') &&
                                                itemForm.controls['question8'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '9. What happens when medicines are stopped' | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question9" id="question9">
                                            <option value=""></option>
                                            <option>{{ 'nothing' | translate }}</option>
                                            <option>{{ 'more spread of disease but manageable' | translate }}</option>
                                            <option>{{ 'flare-ups' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question9'].hasError('required') &&
                                                itemForm.controls['question9'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            '10. Can educational videos help to understand why taking regular medicines is important'
                                                | translate
                                        }}</label>
                                        <select class="form-control" formControlName="question10" id="question10">
                                            <option value=""></option>
                                            <option>{{ 'yes' | translate }}</option>
                                            <option>{{ 'no' | translate }}</option>
                                        </select>
                                        <small
                                            class="text-danger"
                                            *ngIf="
                                                itemForm.controls['question10'].hasError('required') &&
                                                itemForm.controls['question10'].touched
                                            "
                                        >
                                            {{ 'Please complete this question.' | translate }}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-form-label">
                                            {{
                                                'Would you like to receive more educational information and videos relating to medical conditions?'
                                                    | translate
                                            }}
                                            <div>{{ 'If yes please insert your email address below' | translate }}</div>
                                        </label>
                                        <input
                                            class="form-control"
                                            type="email"
                                            formControlName="email"
                                            placeholder="{{ 'Type your email address here to subscribe' | translate }}"
                                        />
                                    </div>
                                </section>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-danger mr-1" (click)="cancel()">
                                    {{ 'Cancel' | translate }}
                                </button>
                                <button
                                    class="btn btn-primary mr-1"
                                    [disabled]="itemForm.invalid && hadAtopicDermatitis === 'yes'"
                                    (click)="submit()"
                                >
                                    {{ 'Submit' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
