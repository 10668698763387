import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-patient-message-card',
    templateUrl: './patient-message-card.component.html',
    styleUrls: ['./patient-message-card.component.scss'],
})
export class PatientMessageCardComponent {
    @Input() poster = '';
    @Input() src = '';
    @Input() title = '';
    @Input() description = '';
    @Input() bulletPoints = [];
    constructor() {}
}
