// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    firebaseConfig: {
        apiKey: 'AIzaSyAaKYHgzFrp_SDLAsubN693SI6985DdkA8',
        authDomain: 'patient-academy.firebaseapp.com',
        databaseURL: 'https://patient-academy.firebaseio.com',
        projectId: 'patient-academy',
        storageBucket: 'patient-academy.appspot.com',
        messagingSenderId: '797293477524',
        appId: '1:797293477524:web:36d9ef778163ce6f8cbe81',
        measurementId: 'G-QC1HNQZ4CT',
        storageBaseUrl: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
