<div class="page-title-area page-banner-faq">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Frequently Asked Questions' | translate }} - {{ 'Mental Health' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Faq</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>{{ 'Mental Health' | translate }} {{ 'FAQs' | translate }}</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>{{ 'Is there a helpline or support in my country?' | translate }}</a>
                            <p>
                                {{
                                    'Click on the Support menu tab at the top of the screen to find support in your area.'
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>{{ 'Who do I contact to find out more about Mental Health?' | translate }}</a>
                            <p>
                                {{
                                    'Watch the videos available on our site or click on the Resources Tab at the top of the screen.'
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>{{ 'What is anxiety?' | translate }}</a>
                            <p>{{ 'Watch video 2 to find out what anxiety is.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>{{ 'Why do I feel on edge?' | translate }}</a>
                            <p>{{ 'Watch video 2 to understand why you feel the way you are feeling' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'What is noncommunicable diseases?' | translate }}</a>
                            <p>{{ 'Watch video 3 to find out more.' | translate }}</p>
                        </li>
                        <!-- <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'What is noncommunicable diseases?' | translate }}</a>
                            <p>
                                {{
                                    'Watch video 2 to find out how noncommunicable diseases and Mental Health are related.'
                                        | translate
                                }}
                            </p>
                        </li> -->
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{
                                'Should I be worried about my Mental Health if I have a noncommunicable disease?'
                                    | translate
                            }}</a>
                            <p>{{ 'Watch video 3 to find out more.' | translate }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
