import { Component } from '@angular/core';

@Component({
    selector: 'app-support-group-pain',
    templateUrl: './support-group-pain.component.html',
    styleUrls: ['./support-group-pain.component.scss'],
})
export class SupportGroupPainComponent {
    constructor() {}
}
