import { Component } from '@angular/core';

@Component({
    selector: 'app-support-group-cardiovascular-disease',
    templateUrl: './support-group-cardiovascular-disease.component.html',
    styleUrls: ['./support-group-cardiovascular-disease.component.scss'],
})
export class SupportGroupCardiovascularDiseaseComponent {
    constructor() {}
}
