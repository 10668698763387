import { Component } from "@angular/core";

@Component({
    selector: "app-technical-support",
    templateUrl: "./technical-support.component.html",
    styleUrls: ["./technical-support.component.scss"],
})
export class TechnicalSupportComponent {
    constructor() {}
}
