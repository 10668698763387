import { Component } from '@angular/core';

@Component({
    selector: 'app-link-app',
    templateUrl: './app-link.component.html',
    styleUrls: ['./app-link.component.scss'],
})
export class AppLinkComponent {
    constructor() {}
}
