<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-xl-12">
            <div class="row">
                <div class="col-sm-12">
                    <!-- <div class="card"> -->
                    <div class="card-header">
                        <img [src]="'home-banner' | translate" alt="About" />
                    </div>
                    <div class="card-body">
                        <br />
                        <h4>{{ 'Vision' | translate }}</h4>
                        <p>
                            {{
                                'The vision of the Patient Academy is to create communities of empowered patients that take ownership in the management of their medical condition through customized health information.'
                                    | translate
                            }}
                        </p>
                        <br />
                        <h4>{{ 'Principles' | translate }}</h4>
                        <br />
                        <h5>{{ 'Empowerment' | translate }}</h5>
                        <p>
                            {{
                                'The Patient Academy aims to empower patients on relevant medical conditions or issues through health literacy.'
                                    | translate
                            }}
                        </p>
                        <p>
                            {{
                                'Health literacy allows people the ability to access health information that can help to prevent, protect, and manage their medical conditions.'
                                    | translate
                            }}
                            <sup>1, 4</sup>
                            {{
                                'WHO attributes that heath literacy can play an important part in patients actively taking control of their own health.'
                                    | translate
                            }}<sup>2</sup> &nbsp;{{
                                'It is one of the cost-effective approaches that can be used to overcome challenges associated especially with noncommunicable diseases and as an instrument to reach several targets of the Sustainable Development Goals.'
                                    | translate
                            }}<sup>5</sup>
                        </p>
                        <p>
                            {{
                                'WHO has recognized health literacy as a key component in promoting a patient centric approach.'
                                    | translate
                            }}<sup>3 </sup
                            >{{
                                'A patient-centric approach involves and enables participation of the patient in their own health care.'
                                    | translate
                            }}<sup>3</sup>
                        </p>
                        <h5>{{ 'Collaboration' | translate }}</h5>
                        <p>
                            {{
                                'Viatris has collaborated with IAPO with the goal of building a sustainable patient-centered approach to the care of their medical conditions.'
                                    | translate
                            }}
                        </p>
                        <h5>{{ 'Accessibility' | translate }}</h5>
                        <p>{{ 'The Patient Academy has been designed to be:' | translate }}</p>

                        <img [src]="'health-awareness-image' | translate" alt="" />

                        <br />
                        <h4>{{ 'About the site' | translate }}</h4>
                        <p>
                            {{ 'AboutTheSite' | translate }}
                        </p>
                        <img [src]="'infographic-elements-image' | translate" alt="" />

                        <br />
                        <h4>{{ 'References' | translate }}:</h4>
                        <ol>
                            <li>
                                {{ 'Understanding health literacy. CDC' | translate }} [{{
                                    ' ' + 'Available from:' | translate
                                }}
                                <a target="_blank" href="https://www.cdc.gov/healthliteracy/learn/Understanding.html"
                                    >https://www.cdc.gov/healthliteracy/learn/Understanding.html </a
                                >({{ 'Assessed July 2020' | translate }})]
                            </li>
                            <li>
                                {{ 'Improving Health Literacy: WHO.' | translate }}
                                {{ ' ' + '[Available from:' | translate }}"
                                <a target="_blank" href="https://www.who.int/activities/improving-health-literacy"
                                    >https://www.who.int/activities/improving-health-literacy </a
                                >({{ 'Assessed July 2020' | translate }})]
                            </li>
                            <li>
                                {{
                                    'World Health Organization. People-centered health care: a policy framework. Page 9'
                                        | translate
                                }}
                                [{{ 'Available from:' | translate }}'
                                <a target="_blank" href="https://www.who.int/publications/i/item/9789290613176"
                                    >https://www.who.int/publications/i/item/9789290613176</a
                                >
                                ({{ 'Assessed July 2020' | translate }})].
                            </li>
                            <li>
                                {{
                                    'Improving Health Literacy Could Prevent Nearly 1 Million Hospital Visits and Save $25 Billion a Year. United health group.2020.'
                                        | translate
                                }}
                                [{{ ' ' + 'Available from:' | translate }}'
                                <a
                                    target="_blank"
                                    href="https://www.unitedhealthgroup.com/content/dam/UHG/PDF/About/Health-Literacy-Brief.pdf"
                                    >https://www.unitedhealthgroup.com/content/dam/UHG/PDF/About/Health-Literacy-Brief.pdf
                                </a>
                            </li>
                            <li>
                                Chenxi Liu, Dan Wang, Chaojie Liu &zwj;&zwj;
                                {{
                                    'et al, What is the meaning of health literacy? A systematic review and qualitative synthesis. Fam Med Com Health 2020.1-7.'
                                        | translate
                                }}
                            </li>
                        </ol>
                        <p>&nbsp;</p>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-danger mr-1" (click)="close()">{{ 'Close' | translate }}</button>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
