<div class="page-title-area page-banner-about">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'About us' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >About</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
<!--  -->
<!-- About Viatris-->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About" />

                    <h2>{{ 'DevelopedForPatient' | translate }}</h2>
                    <p>
                        {{ 'TheVision' | translate }}
                    </p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            {{ 'Click Play to watch the video on the right' | translate }}
                        </li>
                    </ul>
                    <a (click)="knowMore()" class="white-text"> {{ 'Learn More' | translate }}</a>
                </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <video
                            #video1
                            class="col-lg-10 video-card video-border"
                            id="player"
                            controls
                            controlsList="nodownload"
                            oncontextmenu="return false;"
                            poster="assets/img/banner/Patient-Acad_logo.jpg"
                        >
                            <source [src]="'teaser-video' | translate" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/viatris.png" alt="About" />
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About" />
                    <h2>{{ 'About Viatris' | translate }}</h2>
                    <p>{{ 'AboutViatris' | translate }}</p>

                    <!-- <ul>
                        <li>
                            <i class="icofont-check-circled"></i> Browse Our
                            Website
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i> Choose Service
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i> Send Messege
                        </li>
                    </ul> -->
                    <!-- <button class="blue-style" (click)="knowMore()">Know More</button> -->
                    <!-- <a  class="blue-style" (click)="knowMore()">Know More</a> -->
                    <a href="https://www.viatris.com/en" target="_blank">{{ 'Know more' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- About IAPO-->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About" />
                    <h2>{{ 'About IAPO' | translate }}</h2>
                    <p>
                        {{ 'AboutIAPO' | translate }}
                    </p>

                    <!-- <ul>
                        <li>
                            <i class="icofont-check-circled"></i> Browse Our
                            Website
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i> Choose Service
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i> Send Messege
                        </li>
                    </ul> -->
                    <a href="https://www.iapo.org.uk/" target="_blank">{{ 'Know more' | translate }}</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/IAPO.png" alt="About" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--
<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2 >Our Expertise</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3 >Child Care</h3>
                                    <p >Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3 >More Stuff</h3>
                                    <p >Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3 >Enough Lab</h3>
                                    <p >Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3 >24 Hour Doctor</h3>
                                    <p >Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item">
                    <img src="assets/img/expertise.jpg" class="speciality-image" alt="Speciality" /> -->

<!-- <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3 >Emergency Call</h3>
                        <p >+07 554 332 322</p>
                    </div> -->
<!-- </div>
            </div>
        </div>
    </div>
</section> -->

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <!-- <span>Services</span> -->
            <h2>{{ 'Modules' | translate }}</h2>
        </div>

        <app-modules-cards></app-modules-cards>
    </div>
</section>
