import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { AtopicDermatitisBefore } from 'src/app/models/atopic-dermatitis-before.model';
import { AtopicDermatitisBeforeService } from 'src/app/services/atopicDermatitisBefore.service';

@Component({
    selector: 'app-know-more',
    templateUrl: './know-more.component.html',
    styleUrls: ['./know-more.component.scss'],
})
export class KnowMoreComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<KnowMoreComponent>) {}

    close() {
        this.dialogRef.close();
    }
}
