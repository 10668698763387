<div class="row m-0 align-items-center pb-70">
    <div class="col-lg-6 p-0">
        <div class="video-container">
            <video
                #video1
                class="col-lg-10 video-card video-border"
                id="player"
                controls
                controlsList="nodownload"
                oncontextmenu="return false;"
                [poster]="poster | translate"
                (play)="beforePlayVideo(video1)"
            >
                <source [src]="url | translate" type="video/mp4" />
            </video>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="hospital-item hospital-right">
            <h2>{{ topic | translate }}</h2>
            <p>
                {{ text | translate }}
            </p>
            <ul>
                <li *ngFor="let bullet of bullets"><i class="icofont-check-circled"></i> {{ bullet | translate }}</li>
                <li>
                    <i class="icofont-check-circled"></i>{{ 'Video Length' | translate }} {{ ' ' + videoLength + ' ' }}
                    {{ 'minutes' | translate }}
                </li>
            </ul>
        </div>
    </div>
</div>
