import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { AtopicDermatitisAfterVideo1 } from 'src/app/models/atopic-dermatitis-after-video-1.model';
import { AtopicDermatitisAfterVideo1Service } from 'src/app/services/atopicDermatitisAfterVideo1.service';

@Component({
    selector: 'app-after-question-video-1-popup',
    templateUrl: './after-question-video-1-popup.component.html',
    styleUrls: ['./after-question-video-1-popup.component.scss'],
})
export class AfterQuestionVideo1PopupComponent {
    public atopicDermatitisAfterVideo1: AtopicDermatitisAfterVideo1;

    itemForm = this.fb.group({
        question1: ['', Validators.required],
        question2: ['', Validators.required],
        question3: ['', Validators.required],
        email: [''],
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<AfterQuestionVideo1PopupComponent>,
        private fb: FormBuilder,
        private atopicDermatitisAfterVideo1Service: AtopicDermatitisAfterVideo1Service
    ) {}

    onAtopicDermatitisChange(value) {
        this.itemForm.patchValue({});
    }

    // saveFaq() {
    //     this.faqService.updateFaq(this.faq);
    // }

    submit() {
        // this.isSubmitted = true;
        localStorage.setItem('isSubmitted', 'true');
        const a = { ...this.atopicDermatitisAfterVideo1, ...this.itemForm.value };
        this.atopicDermatitisAfterVideo1Service.saveAtopicDermatitisAfterVideo1(a);
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }

    submitForm(form: NgForm) {
        // this.isSubmitted = true;
        if (!form.valid) {
            return false;
        } else {
            alert(JSON.stringify(form.value));
        }
        this.dialogRef.close();
    }
}
