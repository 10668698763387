import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import { ResourceUrl } from 'src/app/models/resource.model';

interface Resource {
    title: string;
    organization: string;
    url: string;
}

@Component({
    selector: 'app-resources-respiratory-disease',
    templateUrl: './resources-respiratory-disease.component.html',
    styleUrls: ['./resources-respiratory-disease.component.scss'],
})
export class ResourcesRespiratoryDiseaseComponent {
    constructor(public dialogRef: MatDialogRef<ResourcesRespiratoryDiseaseComponent>) {}

    resources: Resource[] = [
        {
            title: 'US',
            organization: 'Healthy People 2030',
            url: 'https://health.gov/healthypeople/objectives-and-data/browse-objectives/respiratory-disease/evidence-based-resources',
        },
        {
            title: 'US',
            organization: 'American Lung Association',
            url: 'https://www.lung.org/lung-health-diseases',
        },
        {
            title: 'US',
            organization: 'Global CHEST Foundation',
            url: 'https://foundation.chestnet.org/',
        },
        {
            title: 'US',
            organization: 'American Thoracic Society (ATS)',
            url: 'https://www.thoracic.org/',
        },
        {
            title: 'US',
            organization: 'American Association for Respiratory Care (AARC)',
            url: 'https://www.aarc.org/',
        },
        {
            title: 'Scotland',
            organization: 'NHS Highland',
            url: 'https://tam.nhsh.scot/respiratory-resource-hub-for-patients-and-carers/',
        },
        {
            title: 'Australia',
            organization: 'Lung Foundation Australia',
            url: 'https://lungfoundation.com.au/patients-carers/living-with-a-lung-disease/copd/resources/',
        },
        {
            title: 'UAE',
            organization: 'Cleveland Clinic Abu Dhabi',
            url: 'https://www.clevelandclinicabudhabi.ae/en/institutes-and-specialties/respiratory-institute/pages/chronic-obstructive-pulmonary-disease.aspx',
        },
        {
            title: 'UAE',
            organization: 'Emirates Thoracic Society',
            url: 'https://www.etsociety.ae/',
        },
        {
            title: 'Europe',
            organization: 'European Respiratory Society',
            url: 'https://www.ersnet.org/',
        },
        {
            title: 'Saudi Arabia',
            organization: 'Saudi Thoracic Society',
            url: 'https://saudithoracicsociety.org/',
        },
        {
            title: 'New Zealand',
            organization: 'Asthma New Zealand',
            url: 'https://www.asthma.org.nz/pages/resources',
        },
        {
            title: 'New Zealand',
            organization: 'Health Navigator',
            url: 'https://www.healthnavigator.org.nz/health-a-z/r/respiratory-diseases/',
        },
        {
            title: 'Malaysia',
            organization: 'Lung Foundation',
            url: 'http://www.lfm.org.my',
        },
        {
            title: 'South Africa',
            organization: 'COPD Foundation South Africa',
            url: 'https://www.copd.co.za/competition.php',
        },
        {
            title: 'China',
            organization: 'West China School of Medicine/West China Hospital of Sichuan University (WCSM/WCH)',
            url: 'https://www.wchscu.cn/details/54364.html',
        },
    ];

    close() {
        this.dialogRef.close();
    }
}
