import { Component } from "@angular/core";

@Component({
    selector: "app-faq-digital-health",
    templateUrl: "./faq-digital-health.component.html",
    styleUrls: ["./faq-digital-health.component.scss"],
})
export class FaqDigitalHealthComponent {
    constructor() {}
}
