<div class="page-title-area page-banner-patient-messages">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Patient stories' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Faq</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<div class="heart-shape">
    <img src="assets/img/home-three/3.png" alt="Banner" />
</div>

<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape" />
        </div>

        <app-patient-message-card
            *ngFor="let patientMessage of patientMessages"
            [poster]="patientMessage.poster"
            [src]="patientMessage.src"
            [title]="patientMessage.title"
            [description]="patientMessage.description"
            [bulletPoints]="patientMessage.bulletPoints"
        ></app-patient-message-card>
    </div>
</section>

<app-modules-cards></app-modules-cards>
