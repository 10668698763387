import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Video } from 'src/app/models/video.model';
// import { BeforePopupMHComponent } from './before-question/before-popup.component';

@Component({
    selector: 'app-respiratory-disease',
    templateUrl: './respiratory-disease.component.html',
    styleUrls: ['./respiratory-disease.component.scss'],
})
export class RespiratoryDiseaseComponent {
    constructor(private dialog: MatDialog) {}

    videos: Video[] = [
        {
            topic: 'Respiratory (COPD*)',
            text: '* Chronic obstructive pulmonary disease',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '4.34',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/respiratory%2FVIDEO%201%20RESPIRATORY%2015%20March%2023%20(1).mp4?alt=media&token=839a0eec-3dbb-46cf-bf75-a9572d95004c',
            poster: 'assets/img/respiratory-poster.png',
            videoNumber: 1,
        },
        {
            topic: 'Dr. Naim Aoun',
            text: 'Dr Naim Aoun helps you get a better understanding of COPD in the Middle East',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '7.27',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/respiratory%2FDR%20NAIM%20PRESENTATION%20V.2%2020April23.mp4?alt=media&token=8626730d-f7b9-4ae0-bd1c-ecc36533dc01',
            poster: 'assets/img/banner/respiratory-video-2.jpg',
            videoNumber: 2,
        },
    ];
}
