import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-resources-cardiovascular-disease',
    templateUrl: './resources-cardiovascular-disease.component.html',
    styleUrls: ['./resources-cardiovascular-disease.component.scss'],
})
export class ResourcesCardiovascularDiseaseComponent {
    constructor(public dialogRef: MatDialogRef<ResourcesCardiovascularDiseaseComponent>) {}

    close() {
        this.dialogRef.close();
    }
}
