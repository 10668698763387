<div class="page-title-area page-banner-support-groups">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Support groups' | translate }} - {{ 'Mental Health' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >{{ 'Modules' | translate }}</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="map">
    <img src="assets/img/map.png" alt="Map" />
</div> -->

<section class="map">
    <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
                <!-- partial:index.partial.html -->
                <div class="distribution-map">
                    <img src="assets/img/map_blue.png" alt="" />

                    <button class="map-point" style="top: 75%; left: 53%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'South Africa' | translate }}</h3>
                                <h6>0861 322 322</h6>
                                <p>{{ 'Support' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://www.mhinnovation.net/organisations/south-african-depression-and-anxiety-group-sadag"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 41.5%; left: 50.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'ITALIA Telefono' | translate }}</h3>
                                <h3>{{ 'Amico Italia' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.telefonoamico.it/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                    <button class="map-point" style="top: 34%; left: 50%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Deutschland' | translate }}</h3>
                                <h6>{{ 'The TelefonSeelsorge®' | translate }}</h6>
                                <h6>{{ 'Eine starke Gemeinschaft' | translate }}</h6>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.telefonseelsorge.de/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                    <button class="map-point" style="top: 37%; left: 47%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'France' | translate }}</h3>
                                <h6>Plus on en parle... Moins on y pense</h6>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.suicide-ecoute.fr/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                    <button class="map-point" style="top: 42%; left: 45%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Espana' | translate }}</h3>
                                <h3>Intervención en Crisis</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://telefonodelaesperanza.org">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 32.5%; left: 45%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'England' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://www.sane.org.uk/how-we-help/emotional-support/saneline-services"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>
                    <button class="map-point" style="top: 30%; left: 48%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Nederlands' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.113.nl/heb-je-nu-hulp-nodig/hulplijn">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                    <button class="map-point" style="top: 41%; left: 20%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'US' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://www.samhsa.gov/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 51%; left: 68%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'India' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="http://aasra.info/">{{ 'Click here to open' | translate }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 76%; left: 82%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Australia' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://mhaustralia.org/need-help">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 45%; left: 75.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'China' | translate }}</h3>
                                <p>{{ 'Beijing Psychological Assistance Hotline' | translate }}</p>
                                <p>800-810-1117</p>
                                <!-- <a  target="_blank" href="https://www.eczemahelp.ca/">{{ 'Click here to open' | translate }}</a> -->
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 82%; left: 14.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Global' | translate }}</h3>
                                <h3>{{ 'The Depression Project' | translate }}</h3>
                                <p>{{ 'Support' | translate }}</p>
                                <a target="_blank" href="https://thedepressionproject.com/emergencyservices">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                </div>

                <!-- partial -->
            </div>
        </div>
    </div>
</section>

<!-- <section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <google-chart
                    #chart
                    [width]="width"
                    resolution="continents"
                    [type]="type"
                    [data]="data"
                    [options]="options"
                >
                </google-chart>
            </div>
        </div>
    </div>
</section> -->

<app-modules-cards></app-modules-cards>
