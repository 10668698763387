<div class="page-title-area page-title-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Technical support' | translate }}</h2>
            </div>
        </div>
    </div>
</div>

<section class="appointment-area-two">
    <div class="container">
        <div class="row align-items-center appointment-wrap-two">
            <div class="col-lg-7 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="appointment-item appointment-item-two">
                    <div class="appointment-shape">
                        <img src="assets/img/appointment/3.png" alt="Shape" />
                    </div>

                    <h2>{{ 'Log a ticket for assistance' | translate }}</h2>
                    <span>{{ 'Our professional team is ready to assist you' | translate }}</span>

                    <div class="appointment-form">
                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-business-man-alt-1"></i>
                                        <label>{{ 'Name' | translate }}</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            [placeholder]="'Enter Your Name' | translate"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-ui-message"></i>
                                        <label>{{ 'Email' | translate }} </label>
                                        <input
                                            type="email"
                                            class="form-control"
                                            [placeholder]="'Enter Your Email' | translate"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <i class="icofont-hospital"></i>
                                        <label
                                            >{{ 'Technical support' | translate }} {{ 'Categories' | translate }}</label
                                        >
                                        <select class="form-control">
                                            <option>{{ 'Video not loading' | translate }}</option>
                                            <option>{{ 'Poor quality video' | translate }}</option>
                                            <option>{{ 'Language not available' | translate }}</option>
                                            <option>{{ "Couldn't answer questionnaire" | translate }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center">
                                <button type="submit" class="btn appointment-btn">{{ 'Submit' | translate }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="appointment-item-two-right">
                    <div class="appointment-item-content">
                        <h2>{{ 'Working Hours' | translate }}</h2>
                        <div class="content-one">
                            <ul>
                                <li>{{ 'Monday' | translate }}</li>
                                <li>{{ 'Tuesday' | translate }}</li>
                                <li>{{ 'Wednesday' | translate }}</li>
                                <li>{{ 'Thursday' | translate }}</li>
                                <li>{{ 'Friday' | translate }}</li>
                                <!-- <li >Saturday</li> -->
                            </ul>
                        </div>
                        <div class="content-two pb-70">
                            <ul>
                                <li>{{ '9:00 - 16:00' | translate }}</li>
                                <li>{{ '9:00 - 16:00' | translate }}</li>
                                <li>{{ '9:00 - 16:00' | translate }}</li>
                                <li>{{ '9:00 - 16:00' | translate }}</li>
                                <li>{{ '9:00 - 16:00' | translate }}</li>
                                <!-- <li >9:00-4:00 (GMT+1)</li> -->
                            </ul>
                        </div>

                        <div>* {{ 'UK time zone (GMT+1)' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>{{ 'FAQs for Technical Support' | translate }}</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>{{ 'Can I ask questions in my local language?' | translate }}</a>
                            <p>
                                {{
                                    'As of now you cannot register any query in local language. You can register your query in English.'
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>{{ 'What happens when I open a support case?' | translate }}</a>
                            <p>
                                {{
                                    'When you register a query in the technical assistance section a team of experts will address the query and reply to you over a mail.'
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>{{ 'How to Find Your Missing module?' | translate }}</a>
                            <p>
                                {{
                                    'Since we do not provide a watchlist option, you can go to search section on homepage and find the module that you want, to find your module you can follow this process:'
                                        | translate
                                }}
                            </p>
                            <p>{{ '1. Click on home module' | translate }}</p>
                            <p>{{ '2. Search the module' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>{{ 'Where can I go for help?' | translate }}</a>
                            <p>{{ 'You can go to the FAQ section or the technical support section.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'How do I delete Internet cookies?' | translate }}</a>
                            <p>
                                {{
                                    "Microsoft Internet Explorer users can go to 'Tools' (or the little cog icon in the top left, then go to 'Safety' and choose 'Delete browsing history...', you can then choose to delete your Internet cookies."
                                        | translate
                                }}
                            </p>
                            <p>
                                {{
                                    "In Google Chrome, go to 'More Tools' and choose 'Clear browsing data...'."
                                        | translate
                                }}
                            </p>
                            <p>
                                {{
                                    "Firefox users can go to 'History', then choose 'Clear recent history...'"
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'How to delete cache on google chrome?' | translate }}</a>
                            <p>{{ '1. On your computer, open Chrome.' | translate }}</p>
                            <p>{{ '2. At the top right, click More.' | translate }}</p>
                            <p>{{ '3. Click More tools. Clear browsing data.' | translate }}</p>
                            <p>
                                {{
                                    '4. At the top, choose a time range. To delete everything, select All time.'
                                        | translate
                                }}
                            </p>
                            <p>
                                {{
                                    '5. Next to "Cookies and other site data" and "Cached images and files," check the boxes.'
                                        | translate
                                }}
                            </p>
                            <p>{{ '6. Click Clear data' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'How to delete cache on safari?' | translate }}</a>
                            <p>
                                {{
                                    '1. To clear your history and cookies, go to Settings > Safari, and tap Clear History and Website Data. ...'
                                        | translate
                                }}
                            </p>
                            <p>
                                {{
                                    '2. To clear your cookies and keep your history, go to Settings > Safari > Advanced > Website Data, then tap Remove All Website Data.'
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'Why is my Video not loading?' | translate }}</a>
                            <p>{{ '1. Please check your internet connection, please check for cache.' | translate }}</p>
                            <p>{{ '2. Restart your pc.' | translate }}</p>
                            <p>{{ '3. Restart or switch your network.' | translate }}</p>
                            <p>{{ '4. Delete temp, cache, and cookies.' | translate }}</p>
                            <p>{{ '5. Delete browsing history.' | translate }}</p>
                            <p>{{ '6. Delete any other applications.' | translate }}</p>
                            <p>
                                {{
                                    '7. If none of them work, please register a query to technical support' | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'Why does my video stop abruptly after playing for some time?' | translate }}</a>
                            <p>{{ 'Please refresh your page or restart the website.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'I can only see the video but not audio?' | translate }}</a>
                            <p>{{ '1. Please update your audio drivers.' | translate }}</p>
                            <p>{{ '2. Refresh your website.' | translate }}</p>
                            <p>{{ '3. Report to technical assistance.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'How is my survey used?' | translate }}</a>
                            <p>{{ 'Your survey is used to better understand the user experience.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'Will the survey capture any personal data?' | translate }}</a>
                            <p>
                                {{
                                    'Yes we do collect your Email ID to keep you updated with any new trends on our newsletter.'
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'How do I send my regards and concerns?' | translate }}</a>
                            <p>
                                {{
                                    'You can share your regards and concerns through our technical assistance section.'
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'Why do I see a blur videos?' | translate }}</a>
                            <p>
                                {{
                                    'Unstable internet connect or change the resolution from the option section in the video to 1080P.'
                                        | translate
                                }}
                            </p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'Can I download the video?' | translate }}</a>
                            <p>{{ 'No.' | translate }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
