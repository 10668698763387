import { Component } from "@angular/core";

@Component({
    selector: "app-faq-respiratory",
    templateUrl: "./faq-respiratory.component.html",
    styleUrls: ["./faq-respiratory.component.scss"],
})
export class FaqRespiratoryComponent {
    constructor() {}
}
