<div class="page-title-area page-banner-cardiovascular-disease">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Respiratory</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Blogs</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<div class="heart-shape">
    <img src="assets/img/home-three/3.png" alt="Banner" />
</div>
<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape" />
        </div>

        <app-video-card
            *ngFor="let video of videos"
            [topic]="video.topic"
            [text]="video.text"
            [bullets]="video.bullets"
            [videoLength]="video.videoLength"
            [url]="video.url"
            [poster]="video.poster"
            [videoNumber]="video.videoNumber"
            [enableBeforeQuestions]="false"
            [enableAfterQuestions]="false"
        ></app-video-card>

        <!-- </div> -->
        <div class="pdf">
            <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item video-nav-item">
                    <a download="Respiratory" target="_blank" href="assets/pdf/Respiratory.pdf"
                        ><button
                            class="nav-link active"
                            id="pills-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                        >
                            {{ 'Download Poster' | translate }}
                        </button>
                    </a>
                </li>
            </ul>
            <br />
            <pdf-viewer
                [src]="'assets/pdf/Respiratory.pdf'"
                [rotation]="0"
                [original-size]="false"
                [show-all]="true"
                [fit-to-page]="false"
                [zoom]="1"
                [zoom-scale]="'page-width'"
                [stick-to-page]="false"
                [render-text]="true"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
            ></pdf-viewer>
        </div>
    </div>
</section>

<app-faq-respiratory></app-faq-respiratory>
