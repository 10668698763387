import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Video } from 'src/app/models/video.model';
// import { BeforePopupMHComponent } from './before-question/before-popup.component';

@Component({
    selector: 'app-liver-disease',
    templateUrl: './liver-disease.component.html',
    styleUrls: ['./liver-disease.component.scss'],
})
export class LiverDiseaseComponent {
    constructor(private dialog: MatDialog) {}

    videos: Video[] = [
        {
            topic: 'Basics of Liver',
            text: 'Watch this video to understand the importance and basic functions of the liver.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '3.18',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/liver-disease%2F1.%20Basics%20of%20Liver.mp4?alt=media&token=717f66a6-dd23-4b95-86e2-7d640a62055e',
            poster: 'assets/img/liver-disease/1.png',
            videoNumber: 1,
        },
        {
            topic: 'Drug Induced Liver Injury (DILI)',
            text: 'Do you take frequent medications without consulting your physician? Watch this video now.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '2.44',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/liver-disease%2F2.%20Drug%20Induced%20Liver%20Injury%20(DILI).mp4?alt=media&token=3cfa46a1-1bd7-42df-a420-21122e2cc44b',
            poster: 'assets/img/liver-disease/2.png',
            videoNumber: 2,
        },
        {
            topic: 'Metabolic dysfunction Associated Fatty Liver Disease (MAFLD)',
            text: 'Get ready to learn about Fatty Liver Disease and its association with metabolic conditions like diabetes, obesity, dyslipidemia, and hypertension.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '3.50',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/liver-disease%2F3.%20Metabolic%20dysfunction%20Associated%20Fatty%20Liver%20Disease%20(MAFLD).mp4?alt=media&token=d563cd03-accd-4d89-9a39-b021b0ab5aa1',
            poster: 'assets/img/liver-disease/3.png',
            videoNumber: 3,
        },
        {
            topic: 'Alcohol Related Liver Disease (ARLD)',
            text: 'Watch as Dr. Molly educates Alexandra and Craig about the impact of alcohol on liver health.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '3.58',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/liver-disease%2F4.%20Alcohol%20Related%20Liver%20Disease%20(ARLD).mp4?alt=media&token=e5c07535-236a-401c-9e50-3a6930ca7101',
            poster: 'assets/img/liver-disease/4.png',
            videoNumber: 4,
        },
        {
            topic: 'Viral Hepatitis',
            text: 'Find out more about the different types of viral hepatitis.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '3.54',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/liver-disease%2F5.%20Viral%20Heptitis.mp4?alt=media&token=6fd1ea52-f051-4cf8-ab46-11136a6d96aa',
            poster: 'assets/img/liver-disease/5.png',
            videoNumber: 5,
        },
    ];
}
