import { Component } from '@angular/core';

@Component({
    selector: 'app-support-group-mental-health',
    templateUrl: './support-group-mental-health.component.html',
    styleUrls: ['./support-group-mental-health.component.scss'],
})
export class SupportGroupMentalHealthComponent {
    constructor() {}
}
