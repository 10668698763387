import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

interface Resource {
    title: string;
    organization: string;
    url: string;
}

@Component({
    selector: 'app-resources-pain',
    templateUrl: './resources-pain.component.html',
    styleUrls: ['./resources-pain.component.scss'],
})
export class ResourcesPainComponent {
    constructor(public dialogRef: MatDialogRef<ResourcesPainComponent>) {}

    resources: Resource[] = [
        {
            title: 'US',
            organization: 'Chronic Pain | Johns Hopkins Medicine',
            url: 'https://www.hopkinsmedicine.org/health/conditions-and-diseases/chronic-pain#:~:text=Chronic%20pain%20is%20long%20standing,physical%20activity%2C%20or%20enjoy%20life.',
        },
        {
            title: 'US',
            organization: 'Chronic Pain, What Is It, Causes, Symptoms & Treatment | Cleveland Clinic',
            url: 'https://my.clevelandclinic.org/health/diseases/4798-chronic-pain',
        },
        {
            title: 'Scotland',
            organization: 'Chronic pain - Illnesses and conditions | NHS inform',
            url: 'https://www.nhsinform.scot/illnesses-and-conditions/brain-nerves-and-spinal-cord/chronic-pain',
        },
        {
            title: 'US',
            organization: 'Chronic Pain Syndrome: Symptoms, Causes, Diagnosis, Treatment',
            url: 'https://www.webmd.com/pain-management/chronic-pain-syndrome-overview',
        },
        {
            title: 'Australia',
            organization: 'Chronic pain - about, causes and management | healthdirect',
            url: 'https://www.healthdirect.gov.au/chronic-pain',
        },
        {
            title: 'Australia',
            organization: 'Chronic pain explained',
            url: 'https://www.nps.org.au/consumers/chronic-pain-explained',
        },
        {
            title: 'US',
            organization: 'The American College of Rheumatology',
            url: 'https://www.physio-pedia.com/The_American_College_of_Rheumatology_2010_preliminary_diagnostic_criteria_for_fibromyalgia',
        },
    ];

    close() {
        this.dialogRef.close();
    }
}
