<footer>
    <!-- <div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <h2 >Join Our Newsletter</h2>
                        <p >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod.
                        </p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <div class="newsletter-form">
                            <form class="newsletter-form">
                                <input
                                    type="email"
                                    class="form-control"
                                    placeholder="Enter Your Email"
                                    name="EMAIL"
                                />
                                <button
                                    class="btn newsletter-btn"
                                    type="submit"
                                >
                                    Subscribe
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-feedback">
                        <h3>{{ 'Disclaimer' | translate }}</h3>
                        <div class="disclaimer">
                            {{
                                'The website and content are for educational purposes only and do not reflect any diagnosis or treatment specific to your condition. For queries regarding your condition, please consult a qualified medical professional.'
                                    | translate
                            }}
                        </div>
                        <br />
                        <div class="footer-quick">
                            <ul>
                                <li>
                                    <a href="https://www.viatris.com/en/viatris-privacy-notice" target="_blank">{{
                                        'Privacy Policy' | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a href="https://www.viatris.com/en/cookie-notice" target="_blank">{{
                                        'Cookie Policy' | translate
                                    }}</a>
                                </li>
                                <!-- <li><a  routerLink="/blog">Blog</a></li>
                                <li>
                                    <a  routerLink="/blog-details">Our Expertise</a>
                                </li>
                                <li><a  routerLink="/faq">Faq</a></li>
                                <li><a  routerLink="/doctor">Doctors</a></li>
                                <li><a  routerLink="/contact">Contact us</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-1"></div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>{{ 'Adverse event reporting' | translate }}</h3>
                        <!-- <ul>
                            <li><a  routerLink="/">Pain</a></li>
                            <li><a  routerLink="/">Cardiology</a></li>
                            <li><a  routerLink="/">{{ 'Mental Health' | translate }}</a></li>
                            <li><a  routerLink="/">Massage Therapy</a></li>
                            <li><a  routerLink="/">Ambluance Sevices</a></li>
                            <li><a  routerLink="/">Medicine</a></li>
                        </ul> -->
                        <div class="disclaimer">
                            {{ 'To report any adverse event for our company product, please email to' | translate }}
                            <br />
                            <a class="disclaimer" href="mailto:ProductSafety@viatris.com">ProductSafety@viatris.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-1"></div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h3>{{ 'Contact us' | translate }}</h3>
                        <ul>
                            <li>
                                <i class="icofont-ui-message"></i>
                                <a href="mailto:info@patient-academy.com">info@thepatientacademy.com</a>
                                <a href="mailto:hello@patient-academy.com">hello@thepatientacademy.com</a>
                            </li>
                            <!-- <li>
                                <i class="icofont-stock-mobile"></i>
                                <a  href="tel:+07554332322"
                                    >Call: +07 554 332 322</a
                                >
                                <a  href="tel:+236256256365"
                                    >Call: +236 256 256 365</a
                                >
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>
                                210-27 Quadra, Market Street, Victoria Canada
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3 >Explore</h3>
                        <ul>
                            <li>
                                <a  routerLink="/privacy-policy"
                                    >Privacy Policy</a
                                >
                            </li>
                            <li>
                                <a  routerLink="/terms-condition"
                                    >Terms and Conditions</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3 >Links</h3>
                        <ul>
                            <li><a  routerLink="/">Pain</a></li>
                            <li><a  routerLink="/">Cardiology</a></li>
                            <li><a  routerLink="/">{{ 'Mental Health' | translate }}</a></li>
                            <li><a  routerLink="/">Massage Therapy</a></li>
                            <li><a  routerLink="/">Ambluance Sevices</a></li>
                            <li><a  routerLink="/">Medicine</a></li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>
                {{ 'Footer' | translate }}'
                <!-- <a  href="https://dilowa.com/" target="_blank"
                    >Dilowa</a>
                -->
            </p>
        </div>
    </div>
</div>
