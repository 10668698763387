<div class="page-title-area page-banner-support-groups">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Support groups' | translate }} - {{ 'Cardiovascular Disease' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >{{ 'Modules' | translate }}</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="map">
    <img src="assets/img/map.png" alt="Map" />
</div> -->

<section class="map">
    <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
                <!-- partial:index.partial.html -->
                <div class="distribution-map">
                    <img src="assets/img/map_red.png" alt="" />

                    <button class="map-point" style="top: 33.5%; left: 47%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'London' | translate }}</h3>
                                <h6>{{ 'British Heart Foundation' | translate }}</h6>
                                <p>{{ 'Support' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://www.bhf.org.uk/informationsupport/support/support-groups-and-online-communities"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 39.5%; left: 18.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'US' | translate }}</h3>
                                <p>{{ 'American Heart Association' | translate }}</p>
                                <a target="_blank" href="https://www.heart.org/en/health-topics/caregiver-support">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <!-- <button class="map-point" style="top: 42.5%; left: 22.5%">
                        <div class="content">
                            <div class="centered-y">
                                <h3 >US</h3>
                                <h6 ></h6>
                                <p >North American Thrombosis Forum</p>
                                <a  target="_blank" href="https://www.thrombosis.org/patients/">{{ 'Click here to open' | translate }}</a>
                            </div>
                        </div>
                    </button> -->

                    <button class="map-point" style="top: 75%; left: 53%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'South Africa' | translate }}</h3>
                                <p>{{ 'The Heart and Stroke Foundation South Africa' | translate }}</p>
                                <a target="_blank" href="https://www.heartfoundation.co.za/support/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 76%; left: 81%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Australia' | translate }}</h3>
                                <p>{{ 'Heart Support Australia' | translate }}</p>
                                <a target="_blank" href="https://www.heartsupport.org.au/support/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 70.5%; left: 33%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Brazil' | translate }}</h3>
                                <p>
                                    {{
                                        'ABRAF – Brazilian Pulmonary Hypertension and Related Diseases Association'
                                            | translate
                                    }}
                                </p>
                                <a
                                    target="_blank"
                                    href="https://abraf-ong.translate.goog/grupos-de-apoio/?_x_tr_sl=pt&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=sc"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 28%; left: 46%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'UK' | translate }}</h3>
                                <p>{{ 'Thrombosis UK' | translate }}</p>
                                <a target="_blank" href="https://thrombosisuk.org/what-is-thrombosis.php">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 77.5%; left: 85%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Australia' | translate }}</h3>
                                <p>{{ 'Thrombosis Australia' | translate }}</p>
                                <a
                                    target="_blank"
                                    href="https://www.pbi.org.au/Listing/Site/thrombosis-australia/Category/about-thrombosis"
                                    >{{ 'Click here to open' | translate }}</a
                                >
                            </div>
                        </div>
                    </button>

                    <button class="map-point" style="top: 34%; left: 44%">
                        <div class="content">
                            <div class="centered-y">
                                <h3>{{ 'Ireland' | translate }}</h3>
                                <p>{{ 'Thrombosis Itreland' | translate }}</p>
                                <a target="_blank" href="https://thrombosis.ie/">{{
                                    'Click here to open' | translate
                                }}</a>
                            </div>
                        </div>
                    </button>
                </div>

                <!-- partial -->
            </div>
        </div>
    </div>
</section>

<!-- <section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <google-chart
                    #chart
                    [width]="width"
                    resolution="continents"
                    [type]="type"
                    [data]="data"
                    [options]="options"
                >
                </google-chart>
            </div>
        </div>
    </div>
</section> -->

<app-modules-cards></app-modules-cards>
