<div class="navbar-area">
    <!-- <div class="navbar-area sticky-top"> -->
    <div class="mobile-nav">
        <!-- <a  routerLink="/" class="logo"
            ><img src="assets/img/logo.png" alt="Logo"
        /></a> -->
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- <a  class="navbar-brand" routerLink="/"
                    ><img src="assets/img/logo.png" alt="Logo"
                /></a> -->

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/home" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">{{ 'Home' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">{{ 'About us' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/modules" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }">{{ 'Modules' | translate }}</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/atopic-dermatitis" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Atopic Dermatitis' | translate }}</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/mental-health" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Mental Health' | translate }}</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/cardiovascular-disease" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Cardiovascular Disease' | translate }}</a>
                                </li>
                                <li class="nav-item" *ngIf="showRespiratory">
                                    <a routerLink="/respiratory-disease" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Respiratory' | translate }}</a>
                                </li>
                                <li class="nav-item" *ngIf="showLiverDisease">
                                    <a routerLink="/liver-disease" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Liver Disease' | translate }}</a>
                                </li>
                                <li class="nav-item" *ngIf="showPain">
                                    <a routerLink="/pain" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Pain' | translate }}</a>
                                </li>
                                <li class="nav-item" *ngIf="showOphthalmology">
                                    <a routerLink="/ophthalmology" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Ophthalmology' | translate }}</a>
                                </li>
                                <li class="nav-item" *ngIf="showUrology">
                                    <a routerLink="/urology" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Urology' | translate }}</a>
                                </li>
                                <li class="nav-item" *ngIf="showSharedDecisionMaking">
                                    <a routerLink="/shared-decision-making" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Shared Decision Making' | translate }}</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/resources" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }">{{ 'Resources' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{
                                    exact: true
                                }">{{ 'Support groups' | translate }}</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/support-group-atopic-dermatitis" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Atopic Dermatitis' | translate }}</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/support-group-mental-health" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Mental Health' | translate }}</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/support-group-cardiovascular-disease" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Cardiovascular Disease' | translate }}</a>
                                </li>
                                <li class="nav-item" *ngIf="showRespiratory">
                                    <a routerLink="/support-group-respiratory" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Respiratory' | translate }}</a>
                                </li>

                                <li class="nav-item" *ngIf="showLiverDisease">
                                    <a routerLink="/support-group-liver-disease" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Liver Disease' | translate }}</a>
                                </li>

                                <li class="nav-item" *ngIf="showPain">
                                    <a routerLink="/support-group-pain" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Pain' | translate }}</a>
                                </li>

                                <li class="nav-item" *ngIf="showSharedDecisionMaking">
                                    <a routerLink="/shared-decision-making" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Shared Decision Making' | translate }}</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/patient-stories" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }">{{ 'Patient stories' | translate }}</a>
                        </li>

                        <li *ngIf="showApp" class="nav-item">
                            <a routerLink="/app" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{
                                    exact: true
                                }">{{ 'Eczema App' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/technical-support" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }">{{ 'Technical support' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{
                                    exact: true
                                }">{{ 'Language' | translate }}</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a (click)="setLanguage($event, 'en')" [routerLink]="['/']"
                                        [queryParams]="{ lang: 'en' }" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'English' | translate }}</a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="setLanguage($event, 'es')" [routerLink]="['/']"
                                        [queryParams]="{ lang: 'es' }" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Spanish' | translate }}</a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="setLanguage($event, 'cn')" [routerLink]="['/']"
                                        [queryParams]="{ lang: 'cn' }" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ 'Mandarin' | translate }}</a>
                                </li>
                            </ul>
                        </li>
                        <!--
                        <li class="nav-item language-selector-item">
                            <app-language-selector class="nav-padding"></app-language-selector>
                        </li> -->
                    </ul>

                    <!-- <div class="nav-srh">
                        <div class="search-toggle">
                            <button class="search-icon icon-search">
                                <i class="icofont-search-1"></i>
                            </button>
                            <button class="search-icon icon-close">
                                <i class="icofont-close"></i>
                            </button>
                        </div>

                        <div class="search-area">
                            <form>
                                <input
                                    type="text"
                                    class="src-input"
                                    id="search-terms"
                                    placeholder="Search here..."
                                />
                                <button
                                    type="submit"
                                    name="submit"
                                    value="Go"
                                    class="search-icon"
                                >
                                    <i class="icofont-search-1"></i>
                                </button>
                            </form>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>
