<div class="page-title-area page-banner-faq">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ 'Frequently Asked Questions' | translate }} - {{ 'Respiritory' | translate }}</h2>
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Faq</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Respiratory</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>{{ 'What is COPD?' | translate }}</a>
                            <p>{{ 'Watch video 1 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>{{ 'How many people suffer with COPD?' | translate }}</a>
                            <p>{{ 'Watch video 2 to find out more.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>{{ 'How is COPD diagnosed?' | translate }}</a>
                            <p>{{ 'Watch video 2 to find out how COPD is diagnosed.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>{{ 'What are the risk factors of COPD?' | translate }}</a>
                            <p>{{ 'Watch video 1 and 2 to understand the risk factors of COPD.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'What are the symptoms of COPD?' | translate }}</a>
                            <p>{{ 'Watch video 1 for a list of symptoms related to COPD.' | translate }}</p>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>{{ 'How do I manage my COPD?' | translate }}</a>
                            <p>{{ 'Watch video 1 and 2 for tips on COPD management.' | translate }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
